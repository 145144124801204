@import "../../../../../theme/core.less";

.review {
  user-select: none;
  cursor: pointer;
  width: 368px;
  height: 290px;
  max-width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc((100% - 3 * 24px) / 4);
  padding: 24px;
  border-radius: 20px;
  border: 1px solid var(--Grey-30, #e4e7f2);
  .breakpoint(@breakpoint-laptop, {
      flex-basis: calc((100% - 2 * 24px) / 3);
    });

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    height: 100%;

    &__header {
      .h4();
      margin-bottom: 0;
      width: 56px;
      height: 56px;
      background: aliceblue;
      border-radius: 12px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: none;

      img {
        width: 100%;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      color: @text-primary-color;
      flex-grow: 1;

      .author {
        .h5();
        font-weight: 600;
        margin-bottom: 0;
        color: inherit;
      }
      .review-text {
        .main-text();
        line-height: 24px;
        color: inherit;
        flex-grow: 1;
        overflow: hidden;
        display: -webkit-box;
        word-break: break-word;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        &.positive {
          &.clamp3 {
            -webkit-line-clamp: 3;
          }
          &.clamp4 {
            -webkit-line-clamp: 4;
          }
          color: #15B012;
        }
        &.negative {
          -webkit-line-clamp: 1;
          color: #EB5757;
        }
      }
      .date {
        .main-text();
        color: @text-secondary-color;
        line-height: 24px;
      }
    }
  }
}
