@import "../../theme/core.less";

.ooc-dropdown-search {
    min-width: 138px;
    border-radius: 16px!important;

    .software-search__option-label {
        margin-left: 16px!important;
    }

    .ant-select {
        width: 100%;
    }

    .ant-select-selection-search-input {
        height: 100%!important;
    }

    .ant-select-selector {
        border-radius: @step-0 !important;
        .ant-select-selection-placeholder{
            color: @text-primary-color !important;
            font-family: @font-content;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
    }
    .ant-select-arrow{
        .anticon-plus{
            display: none;
        }
    }

    .ant-select-item-option-content {
        display: flex;
        align-items: center;
    }

    .ant-select-item-option-active {
        background: #EFF8FE!important;
    }

    .ant-select-item-group {
        padding: 7px 16px;
        color: @text-secondary-color;
        font-size: 16px;
        border-top: 1px solid @border-color;
    }

    .ant-select-item-option-grouped {
        padding-left: 16px;
    }

    .ant-select-item-empty {
        display: none;
    }


    &__option-img {
        width: 40px !important;
        height: 40px !important;
        object-fit: contain !important;
        border-radius: 10px !important;
        font-size: 18px !important;
    }

    &__menu-item-title {
        display: block;
        padding: 7px 16px;
        color: @text-secondary-color;
        font-size: 16px;
        margin-left: auto;
        margin-right: auto;
        border: none!important;
    }

    &__menu-item-block{
        text-align: center;
    }

    &__close-icon {
        width: 15px;
        height: 15px;
        margin-top: 5px;
        path {
            fill: @text-help-color;
        }
    }
}

