@import "../../../../theme/core.less";


.tag.ant-tag {
  border-radius: 12px;
  border: 1px solid @el-lines-color;
  color: @label-color;
  background: @bg-secondary;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  padding: 8px 20px;
  margin: 4px;
  font-family: @font-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}


