@import "../../theme/core.less";


.file_wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 8px 24px;
  justify-content: center;
}

.file_icon {
  font-size: 20px;
  line-height: 20px;
  color: #E4E7F2;
}

.file_info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: start;
  color: #6F7C98;

  div {
    font-family: "IBM Plex Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  p {
    color: #909EBB;
    font-family: "IBM Plex Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

