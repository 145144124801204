@import '../../theme/core.less';

.ant-input.ooc-input {
    height: 58px;
    padding: 16px 28px;
    border: 1px solid rgba(174, 180, 193, 0.3);
    border-radius: @inp-border-radius;

    font-family: @inp-font-family;
    font-size: @inp-font-size;
    font-style: @inp-font-style;
    line-height: @inp-line-height;
    border-radius: @inp-border-radius;

    &&__textarea {
        padding: 8px 16px;
        overflow: hidden;
        resize: none;
    }
}

