@import '~antd/lib/style/themes/default.less';
@import '~react-quill/dist/quill.snow.css';
@import 'default.less';

.pagination-showTotal {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  color: #909ebb;
}

/* GLOBAL ANTD REWRITE STYLES */

/* DRAG AND DROP STYLES */
.ant-upload.ant-upload-drag {
  background-color: transparent !important;
  border: none !important;
  background: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E4E7F2FF' stroke-width='4' stroke-dasharray='12%2c 10' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e") !important;
}

/* FORM ITEM STYLES */
.ant-form-item-label > label {
  color: @label-color !important;
}

.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block !important;
  margin-left: 4px;
  margin-bottom: 6px;
  color: @brand-primary-color;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

/* MODAL */
.ant-modal-content {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;

  .ant-modal-close {
    top: 24px;
    right: 40px;
  }

  .ant-modal-header {
    padding: 0;
    padding-bottom: 24px;
    margin: 0;
    border-bottom: 1px solid @el-lines-color;
    padding-right: 40px;

    .ant-modal-title {
      .h3();
      color: @text-primary-color;
    }
  }

  .ant-modal-body {
    padding-top: 18px;
    padding-bottom: 28px;
    padding-left: 0;
    padding-right: 0;
  }

  .ant-modal-footer {
    border-top: 1px solid @el-lines-color;
    padding: 24px 0 0 24px;
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      padding: 12px 24px;
      height: initial;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      font-family: @font-content;
      border-radius: 4px;
      &:disabled {
        background: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
      }
    }
    .ant-btn-default {
      border-color: #0071CE;
      color: #0071CE;
    }
    .ant-btn-primary {
      background: linear-gradient(94deg, #007BE0 -0.43%, #0058A0 54.69%);
    }
  }
}

/* DIVIDER */
.ant-divider.ant-divider-horizontal,
.ant-divider-horizontal.ant-divider-with-text {
  color: @label-color !important;
  border-top-color: @border-color !important;
}

/* MESSAGE */
.ant-message {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 20px;
  right: 20px;
  top: auto;
  left: auto;
  &-notice {
    text-align: end;
    &-content {
      &:has(.ant-message-success) {
        color: #FFFFFF;
        border-radius: 4px;
        background: linear-gradient(90deg, #51D54F 0%, #15B012 100%);
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        .anticon {
          font-size: 18px;
          color: #FFFFFF;
        }
      }
      &:has(.ant-message-error) {
        color: #FFFFFF;
        border-radius: 4px;
        background: linear-gradient(90deg, #FF5F5F 0%, #EB5757 100%);
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        .anticon {
          font-size: 18px;
          color: #FFFFFF;
        }
      }
    }
  }
}

/* SPACE */
.ant-space {
  flex-wrap: wrap;
}

.ant-pagination {
  .ant-pagination-total-text {
    flex-grow: 1;
  }
  
  .ant-pagination-prev button:not(:disabled), .ant-pagination-next button:not(:disabled), .ant-pagination-item a, .ant-select.ant-pagination-options-size-changer, .ant-select.ant-pagination-options-size-changer .ant-select-item-option {
    color: #3B4168;
  }

  .ant-pagination-item-active a, .ant-pagination-item:hover a, .ant-pagination-prev button:hover:not(:disabled), .ant-pagination-next button:hover:not(:disabled) {
    color: #1890ff;
  }
  
  .ant-select.ant-pagination-options-size-changer {
    .ant-select-item-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled), .ant-select-item-option.ant-select-item-option-active {
      color: #0071CE;
      background-color: transparent;
    }
  }
}

/* TABLE */
.ant-table {
  &-tbody {
    .ant-table-cell {
      color: #3B4168;
    }
  }
  &-container {
    .ant-table-content {
      .ant-table-thead {
        & > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
          background-color: transparent;
        }
        .ant-table-cell {
          background-color: transparent;
          font-family: "IBM Plex Sans";
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: #909EBB;
        }
      }
    }
  }
}

.ant-form-item-has-error .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content {
  .ql-snow {
    border-color: #ff4d4f;
    &.ql-container {
      border-top-color: #ff4d4f;
      border-bottom-color: transparent;
    }
  }
}

/* DROPDOWN */
.ant-dropdown-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  &.ant-dropdown-open {
    background-color: #E0F1FF;
  }
}

.ant-dropdown-menu {
  display: inline-flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid #E4E7F2;
  background: #FFFFFF;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  .ant-dropdown-menu-item {
    color: #3B4168;
    padding: 0;
    &:hover {
      background-color: transparent;
      color: #0071CE;
    }
    &-disabled {
      color: #BDBDBD !important;
    }
  }
}

.ant-tabs {
  color: #3B4168;
  font-family: @font-content;
  
  .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }
}
