@import "../../../../theme/core.less";

.software-search {
  padding: 32px;
  border-radius: 8px;
  .breakpoint(1600px, {
    padding: 12px 0;
  });

  .search-menu {
    .menu-label {
      .caption();
      margin-bottom: 16px;
      color: @text-secondary-color;
    }

    .menu-divider {
      border-color: @border-color;
      margin: 32px 0;
    }

    .menu-categories {
      border: none;

      li {
        .buttons();
        display: flex;
        height: auto;
        padding: 0;
        color: @text-secondary-color;

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        &.ant-menu-item-selected {
          background-color: transparent;
          color: @primary-color;
        }

        &:active {
          background-color: transparent;
        }
      }
    }

    .menu-filter {
      .ant-radio-inner {
        width: 18px;
        height: 18px;

        &:after {
          top: 2px;
          left: 2px;
          width: 12px;
          height: 12px;
        }
      }
    }

    .breakpoint(@breakpoint-laptop-large, {
      margin-bottom: 20px;
    });
  }

  &__search-block {
    margin-bottom: 24px;
    background-color: @bg-secondary;
    padding: 40px;
    border-radius: 16px;

    .breakpoint(@breakpoint-mobile-large, {
      padding: 30px 24px 30px 24px;
    });
    .breakpoint(@breakpoint-tablet-small, {
      margin: 16px;
    });

    .search-input-wrapper {
      display: flex;
      align-items: center;

      .search-input {
        border-radius: 4px;
        width: 100%;

        .ant-select-selector {
          border-radius: 0px 8px 8px 0px !important;
          border: none !important;
          height: 45px !important;
          display: flex;
          align-items: center;
          padding-left: 24px !important;
          background-color: #FAFAFF !important;
        }

        h2 {
          font-size: 28px;
          line-height: 36px;
          font-weight: 700;
          color: @text-primary-color;
          font-family: @font-headers;

          span {
            color: @text-help-color;
          }
        }

        .large-title {
          display: block;
          @media screen  and (max-width: 1280px) {
            font-size: 24px;
          }
          .breakpoint(@breakpoint-mobile-large, {
            display: none;
          });
        }

        .mobile-title {
          display: none;
          .breakpoint(@breakpoint-mobile-large, {
            display: block;
            font-size: 24px;
          });
        }
      }
    }
  }

  &__results-block {
    margin-bottom: 60px;

    .results-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .results-title {
        color: @text-primary-color;
        .h3();

        .breakpoint(@breakpoint-tablet-small, {
          .mobile-h3();
        });
      }

      a, button {
        display: flex;
        align-items: center;
        color: @text-link-color;

        svg {
          margin-left: 12px;
        }
      }

      .breakpoint(@breakpoint-tablet-small, {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      });
    }

    .application-card {
      height: 100%;
      .breakpoint(@breakpoint-tablet-small, {
        margin-left: 16px;
        margin-right: 16px;
      });
    }

    .application-card, .vendor-card {
      background: #FAFDFF;
    }
  }

  &__selects {
    max-width: 100%;
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .breakpoint(@breakpoint-laptop-large, {
    display: block;
  });

  .no-results {
    margin-top: 10px;
    margin-left: 17px;

    .no-results-header {
      margin-top: 10px;
      font-size: 24px;
    }
  }

  .card-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .ant-row {
      flex-grow: 1;
    }
    .content-grid {
      width: 100%;
      margin-top: 32px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 32px;
      .breakpoint(@breakpoint-tablet, {
              grid-template-columns: repeat(1, 1fr);
          });
    }
  }
}

.software-search__option-label {
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  &__subtite {
    color: @text-secondary-color;
  }
}

.software-search__option-suffix {
  color: @status-green-color;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 5px;
}

