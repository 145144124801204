@import "../../theme/core.less";

.list-extra-actions {
  display: flex;

  button {
    margin-left: 5px;
  }
}

.ant-tabs-tab-active {
  .tabWithIcon {
    color: @primary-color !important;
  }
}

.admin-panels {
  color: #3b4168;
  &__title {
    color: #3b4168;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 24px;
  }
  .tabWithIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .ant-tabs-tab {
    color: #3b4168;
    .ant-tabs-tab-btn {
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}

.form-block-group-background {
  background-color: @bg-primary;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 24px;

  .col-icon-center {
    padding-top: 36px;
  }

  .item-bottom-0 {
    margin-bottom: 0;
  }
}

.trash-btn {
  color: @el-gray-color;
  cursor: pointer;

  &:hover {
    color: @error-label-color;
  }
}

.duplicate-btn {
  color: @el-gray-color;
  cursor: pointer;

  &:hover {
    color: @label-color;
  }
}

.form-block-group-border {
  position: relative;
  padding: 16px;
  margin-bottom: 32px;
  border: 1px solid @border-color;
  border-radius: 4px;

  .block-group-caption {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .text-bg {
      font-size: 16px;
      color: @text-secondary;
      background: @primary-color;
      border-radius: 20px;
      padding: 2px 12px;
    }
  }
}

.admin-select-dropdown {
  background-color: #fff !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 15px 0px #0000000D !important;
  border: none !important;

  & .ant-select-item {
    color: @text-primary-color !important;
  }

  & .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: @primary-color !important;
    font-weight: normal !important;
  }
}
