@import "../../../../theme/core.less";

.sidebar {
  position: sticky;
  top: 0;
  max-width: 382px;
  margin: 0;
  min-height: 70%;

  .breakpoint(1290px, {
    display: none
  });
}

.catalog-sidebar {
  &__details-block {
    display: flex;
    justify-content: flex-end;
    padding: 20px 15px;
    border-top: 1px solid @border-color;
    cursor: pointer;
    bottom: 0px;
    z-index: 1000;
    background: white;
  }

  &__details-block-hidden {
    display: flex;
    justify-content: flex-end;
    padding: 20px 15px;
    border-top: 1px solid @border-color;
    cursor: pointer;

    svg {
      rotate: (-180deg);
    }
  }

  &__subtitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    font-family: @font-content;
    color: @el-gray-color;
    padding: 0 16px 16px 16px;
    text-transform: uppercase;
    margin: 0;
  }

  &__categories {
    padding: 32px 12px 12px 12px;
    background: @bg-secondary;
    position: sticky;
    top: 10px;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 20px;

    .breakpoint(@breakpoint-laptop-large, {
      padding: 50px;
    });
    .breakpoint(@breakpoint-mobile-large, {
      padding: 50px 20px;
    });

  }

  &__categories-menu {
    margin-top: 24px;
    flex-direction: column;
    overflow-y: auto;
    max-height: 70vh;

    &.ant-menu {
      line-height: 24px;
    }

    &.ant-menu-vertical {
      border-right: none;
    }

    .breakpoint(@breakpoint-tablet, {
      max-height: none;
    });

    .tag.ant-tag {
      padding: 2px 6px;
      border-radius: 8px;
      font-weight: 500!important;
      margin: 0!important;
    }

    &::-webkit-scrollbar {
      width: 8px;

      &-thumb {
        border-left: 4px solid @scroll-color;
        border-radius: 4px;

        &:hover {
          border-left: 6px solid @scroll-color;
        }
      }
    }
  }

  &__categories-menu-item:hover {
    background: @el-hover-2-color !important;
    border-radius: 16px;

    a {
      color: @text-primary-color !important;
    }
    i svg path {
      stroke: @primary-color!important;
    }

    .tag.ant-tag {
      color: @primary-color !important;
      background: transparent!important;
    }

  }

  &__categories-menu-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    align-items: center;
    cursor: pointer;

    &-active {
      background: @el-hover-2-color !important;
      border-radius: 16px;
    }

    a {
      height: auto;
      padding: 0;
      color: @el-gray-color;
      font-size: 16px;
      display: flex;
      font-family: @font-content;
      font-weight: 500;

    }

    i {
      margin-right: 16px;
    }

  }

  &__categories-menu-item-hidden {
    text-align: center;

    svg {
      margin-bottom: 20px;
    }
  }

  &__categories-menu-item-title {
    margin-right: 20px;
    //padding-top: 2px;
  }

}

.skeleton-item {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.skeleton-row {
  display: flex;
  flex-direction: row;
  height: 20px;
}

.categories-skeleton {
  width: 382px;
  min-height: 80vh;
}

