@import "../../../../../../theme/core.less";

.admin-dashboard-pane__row {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

.admin-dashboard-pane__row-item {
  flex: 1;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.admin-dashboard-pane__card.ant-card {
  border-radius: 10px;

  .ant-card-body {
    padding: 40px;
  }
}

.admin-dashboard-pane__title {
  .h3();
  margin: 0;
  color: @text-primary-color;
}

.admin-dashboard-pane__subtitle {
  .h5();
  margin: 0;
  color: @text-secondary-color;
}

.admin-dashboard-pane__number {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 48px;
  color: @text-link-color;
  margin: 0;
}

.admin-dashboard-pane__table {
  margin-top: 32px;
  border-bottom: 1px solid @border-color;
}

.admin-dashboard-pane__table-row {
  display: flex;
  padding: 12px 0;
  border-top: 1px solid @border-color;
}

.admin-dashboard-pane__table-row-item {
  flex: 1;
}

.admin-dashboard-pane__table-body {
  .h5();
  color: @text-primary-color;
}

.filter {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .filter-item {
    margin: 0;
    &.filter-select {
      width: 224px;
      .ant-select-selector {
        border-radius: 10px !important;
        border: none !important;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
      }
    }
    &.filter-search {
      width: 330px;
    }
  }
  .filter-resetButton {
    display: inline-flex;
    color: #909EBB !important;
  }
  .ant-space-item:has(.filter-resetButton) {
    display: flex;
    align-items: center;
  }
  &-with-buttons {
    flex-direction: column;
    width: 100%;
    gap: 8px;
    & > * {
      width: 100%;
    }
  }
}
