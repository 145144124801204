@import '../../theme/core.less';

.ant-drawer {
    &.filter-drawer {

        .ant-drawer-mask {
            background: rgba(228, 231, 242, 0.4);
            backdrop-filter: blur(10px);
        }
      

        .ant-drawer-body {
            padding: 12px 12px 50px;
        }

        .ant-drawer-header {
            padding: 0 12px;
            margin-top: 40px;
            border: none;
            border-radius: 0;
    
            .ant-drawer-close {
                top: -20px;
            }
    
            .ant-drawer-title {
                .h4();
                color: @text-primary-color;
            }
        }

        .ant-drawer-content {
            .filter-btn {
                position: absolute;
                left: 12px;
                right: 12px;
                width: calc(100% - 24px);
                bottom: 12px;
            }
        }
    }
}

