@import "../../../../../../theme/core.less";

.admin-applications-pane {
  .admin-item-title {
    font-weight: bold;
  }

  .admin-list .admin-item-vendor {
    font-style: italic;
  }

  .ant-tabs-tab {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}

.invalid-button {
  background: @text-help-color !important;
  color: @text-secondary !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: @primary-color!important;
}

.ant-tabs .ant-tabs-nav .ant-tabs-ink-bar {
  background-color: @primary-color!important;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 28px !important;
  line-height: 28px !important;
  background-color: #f0f7fc;
  border: none;
}

.ant-select-multiple .ant-select-selection-item-content {
  font-size: 14px;
  color: #70a4cf;
}

.required::after {
  position: relative;
  top: -3px;
  display: inline-block !important;
  margin-left: 4px;
  margin-bottom: 6px;
  color: #0071ce;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

