@import "../../theme/core.less";

.tab-result {
    font-family: @font-headers;
    color: @bc-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 64px;
    color: #3B4168;

    &__icon {
        margin-top: 64px;
    }

    &__title {
        margin-top: 40px;
        .h4();
    }

    &__sub-title {
        .main-text();
    }

    &__description {
        .main-text();
        max-width: 390px;
        text-align: center;
    }

    &--type_success {
        .tab-result__description {
            margin-top: 40px;
        }
    }
}

