@import "../../theme/core.less";

.ooc-page-header {
    display: flex;
    align-items: baseline;

    .ant-page-header {
        margin-bottom: 24px;
        padding: 0;
        display: flex;
        align-items: baseline;

        .ant-page-header-heading {
            &-left {
                align-items: baseline;
                flex-flow: wrap;
            }

            &-title {
                .h2();
                color: @text-primary-color;
                margin-right: 32px;
            }

            &-sub-title {
                .caption();
                color: @text-secondary-color;
            }
        }
    }

    &__action-title > .ooc-button-v2 {
        margin-left: 4px;
        height: fit-content;

        & > span {
            color: @primary-color;

            &::first-letter {
                font-size: 18px;
                margin-right: 8px;
            }
        }
    }
}

