@import "../../../../theme/core.less";

.vertical-application-card {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  max-width: 706px;
  padding: 24px;
  gap: 24px;
  overflow: hidden;
  cursor: pointer;
  background: @bg-secondary!important;
  .breakpoint(@breakpoint-tablet, {
    padding: 12px;
    margin: 0 16px;
  });

  &__badges {
    position: absolute;
    top: 8px;
    left: 8px;
    display: flex;
    flex-wrap: wrap;
    z-index: 2;
    gap: 8px;
    align-items: center;

    .certificate-icon {
      display: flex;
    }

    .priceBadge {
      word-break: break-word;
      background-color: #73D071;
      color: #FFFFFF;
      border-radius: 100px; 
      padding: 3px 12px 5px 12px;
    }
  }

  .card-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 100%;
    height: 220px;
    border-radius: 16px;
    overflow: hidden;

    .badge {
      position: absolute;
      right: 16px;
      top: 16px;
      z-index: 2;
      display: flex;
      align-items: flex-end;
      flex-direction: column-reverse;
      flex-wrap: wrap;
      flex-shrink: 0;

      .tag {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
      }

      .info-block {
        &__special {
          margin-left: 10px;
        }
      }

      i {
        display: flex;
      }

      span {
        .helptext();
      }

      svg {

        path {
          fill: white;
        }
      }
    }

    .preview-overlay {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    .preview-image {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      z-index: 0;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    .preview-logo {
      width: 100%;
      height: 270px;
      max-width: 100%;
      max-height: 100%;
      border-radius: 24px;
      font-size: 180px;
      font-weight: 600;
    }
  }

  .card-content {
    flex-grow: 1;
    overflow: hidden;

    .content-divider {
      margin-top: 0;
      margin-bottom: 16px;
    }

    .content-title {
      .h5();
      margin-bottom: 8px;
      color: @text-primary-color;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .content-description {
      .caption();
      color: @el-gray-color;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 0!important;
    }
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }

  .card-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  .card-category {
    padding-top: 18px;

    .info-block {
      white-space: nowrap;
      flex-wrap: wrap;
    }

    &__list {
      display: flex;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;

      li {
        padding: 4px 16px 6px 16px;
        border-radius: 100px;
        background: rgba(255, 113, 57, 0.08);
        color: #FF7139;
        margin-right: 8px;
      }
    }
  }
}

