@import '../../theme/core.less';

.ant-input.ooc-input-v2,
.ant-input-number.ooc-input-v2 input {
    height: @inp-v2-height;
    padding: @inp-v2-padding;
    border: @inp-v2-border;
    border-radius: @inp-v2-border-radius;

    font-family: @inp-font-family;
    font-style: @inp-font-style;
    font-size: @inp-v2-font-size;
    line-height: @inp-v2-line-height;
}

.ant-input-number.ooc-input-v2 {
    width: 100%;
    border: 0;
}

.ooc-input-v2 {
    &&__form-input {
        .ant-form-item-label label {
            color: #6F7C98;
        }

        .ant-input {
            border: 1px solid #E4E7F2;
            border-radius: 4px;
        }
    }
}

.ooc-input-v2__textarea {
    & > textarea {
        padding: 8px 16px;
    }

    & > textarea.ant-input-borderless {
        padding: 0;
        height: fit-content;
        overflow: hidden;
        resize: none;
    }
}

