@import '../../theme/core.less';

.ooc-form-item {
    width: 100%;

    & > .label,
    & > .value > .ooc-input {
        font-family: @font-headers;
    }

    & > .value {
        margin-top: @step-10;
    }

    & > .value > .ooc-input {
        font-size: 14px;
        height: fit-content;
        border-radius: @step-1;
        padding: @step-2;
        line-height: @step-2;

        &__textarea {
            line-height: unset;
            max-height: calc(38px * 3);
        }
    }
}

