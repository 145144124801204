@import "../../theme/core.less";

.ooc-dropdown {
    min-width: 138px;

    .ant-select {
        width: 100%;
        border-radius: 8px;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);

        // remove antd focus borders
        &.ant-select-focused {
            .ant-select-selector {
                border-color: @bg-primary !important;
                -webkit-box-shadow: initial !important;
                box-shadow: initial !important;
            }
        }
        // remove antd select borders
        &:not(.ant-select-customize-input) {
            border-color: initial !important;
        }

        // remove ant hover borders
        &:not(.ant-select-disabled):hover {
            .ant-select-selector {
                border-color: @bg-primary !important;
            }
        }
    }

    .ant-select-selector {
        border-radius: @step-1 !important;
    }

// dropdown
    &.ant-select-dropdown {
        top: 280px !important;
        border-bottom-left-radius: @step-1;
        border-bottom-right-radius: @step-1;
    }

// navigation dropdown
    &.nav-dropdown {
        width: fit-content !important;

        .ant-select-single {
            box-shadow: none;

            .ant-select-selector {
                padding: initial;
                background: @bg-primary;
                border-color: @bg-primary;

                .ant-select-selection-item {
                    display: flex;

                    span {
                        font-weight: 600;
                        margin-left: @step-1;
                    }
                }
            }
        }

        &.ant-select-dropdown {
            top: @step-2 !important;
            border-top-left-radius: @step-1;
            border-top-right-radius: @step-1;

            .rc-virtual-list-holder-inner {
                & > .ant-select-item {
                    .nav-item {
                        img:not([alt="hat"]):not([alt="monitor"]) {
                            margin-left: @step-0;
                        }
                    }
                }
            }
        }
    }
}

