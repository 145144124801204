@import "../../theme/core.less";

.admin-applications-pane-sort-dropdown {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid @border-color;

  &__title {
    padding: 8px 16px 0;
    margin: 0;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: @el-gray-color;
  }

  &__menu.ant-dropdown-menu {
    box-shadow: none !important;
    border: none !important;
  }
}

