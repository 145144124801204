@import "../../theme/core.less";

.promo-card {
  border-radius: 20px;
  font-weight: 500;
  font-family: Montserrat;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  max-height: 148px;
  overflow: hidden;

  &__label {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    padding: 24px 0 24px 24px;
    width: 60%;
  }

  &__icon {
    max-width: 160px;
    height: 100%;
    display: flex;
    @media screen and (max-width: 550px){
      width: 40%;
    }
    .breakpoint(@breakpoint-mobile-large, {
      width: 50%;
  });
  }



  &--blue {
    background: @colors-helsinki;
    color: @text-white-color;
  }

  &--green {
    background: @el-green-color;
    color: @text-primary-color;
  }

  &--orange {
    background: @colors-baku;
    color: @text-white-color;
  }

  &--blue-light {
    background: #DBE7FF;
    color: @text-primary-color;
  }

  &--gradient-green {
    background: rgb(113,208,113);
    background: linear-gradient(90deg, rgba(113,208,113,1) 30%, rgba(84,199,126,1) 92%);
    color: @bg-primary;
  }

  &--gradient-blue {
    background: rgb(132,197,246);
    background: linear-gradient(90deg, rgba(132,197,246,1) 0%, rgba(76,155,221,1) 92%);
    color: @bg-primary;
  }

  &--gradient-orange {
    background: rgb(251,194,0);
    background: linear-gradient(90deg, rgba(251,194,0,1) 5%, rgba(255,180,12,1) 92%);
    color: @bg-primary;
  }

  &--gradient-blue-light {
    background: rgb(150,134,243);
    background: linear-gradient(90deg, rgba(150,134,243,1) 5%, rgba(156,126,244,1) 92%);
    color: @bg-primary;
  }
}

