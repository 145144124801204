@import "../../theme/core.less";

.not-found{
  &__main-block{
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
      .error-type{
        color: white;
        font-size: calc(150px + 170 * (100vw / 1920));
        font-weight: 700;
        line-height: 384px;
        font-family: @font-headers;
      }
     
      @media screen and(max-width:450px){
        .error-type{
          font-size: 150px;
        }
      }
    
      .content {
        max-width: 450px;
        text-align: center;
        margin: 0 auto;
        h1{
          font-size: 32px;
          font-family: @font-headers;
          font-weight: 700;
          line-height: 40px;
          color: @text-primary-color;
        }

        p{
          color: @text-secondary-color;
          font-size: 16px;
          font-family: @font-content;
          line-height: 20px;
          font-weight: 400;
          margin-bottom: 40px;
        }
        button{
          font-family: @font-content !important;
          margin: 0 auto;
          width: 137px;
        }
      }
}
}
