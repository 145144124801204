@import "../../../../../../../../theme/core.less";

.remove-with-change-modal {
  color: #3B4168;
  &__category {
    color: #0071CE;
  }

  &__item {
    display: flex;
    gap: 16px;
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;
    width: 50%;
  }

  &__label {
    color: #6F7C98;
    font-size: 14px;
    line-height: 20px; 
  }

  &__item-app {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px
  }

  &__right {
    width: 50%;
    flex-grow: 1;
  }
}

