@import "../../theme/core.less";

.ooc-modal {
  .breakpoint(@breakpoint-mobile-large, {
    &.download-modal {
      .ant-modal-header {
        border-bottom: 1px solid #E4E7F2;
        padding-bottom: 21px;
        margin-bottom: 50px;
      }

      .ant-modal-content {
        padding: 10px 0px 10px 0px;

        .content {
          border-top: none !important;
        }

        .ant-modal-body {
          margin-bottom: 70px;
        }

        .ant-modal-footer {
          display: block;
          padding: 24px 24px 0px 24px;

          button {
            min-width: 100%;
            margin: 0px 0px 10px 0px;
          }
        }
      }

      .ant-modal-close {
        top: 0px;
        right: 0px;
      }

    }
  });

  &.ant-modal {
    max-width: 796px;
  }

  .ant-modal-close {
    top: 24px;
    right: 40px;
  }

  .ant-modal-header {
    padding: 0;
    margin: 7px 0 0 24px;
    border: none;

    .ant-modal-title {
      .h3();
      color: @text-primary-color;
    }
  }

  .ant-modal-content {
    padding: 32px;

    .ant-modal-footer {
      border-top: 1px solid @el-lines-color;
      padding: 24px 0 0 24px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .content {
    padding-top: 40px;
    border-top: 1px solid @el-lines-color;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content-text {
      .h5();
      color: @text-primary-color;
      text-align: center;
      margin: 32px 0;
    }
  }
}

