/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@import '../../../../theme/~react-quill/dist/quill.snow.css';
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  font-size: 24px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.pagination-showTotal {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  color: #909ebb;
}
/* GLOBAL ANTD REWRITE STYLES */
/* DRAG AND DROP STYLES */
.ant-upload.ant-upload-drag {
  background-color: transparent !important;
  border: none !important;
  background: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E4E7F2FF' stroke-width='4' stroke-dasharray='12%2c 10' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e") !important;
}
/* FORM ITEM STYLES */
.ant-form-item-label > label {
  color: #6F7C98 !important;
}
.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}
.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block !important;
  margin-left: 4px;
  margin-bottom: 6px;
  color: #0071CE;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
/* MODAL */
.ant-modal-content {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}
.ant-modal-content .ant-modal-close {
  top: 24px;
  right: 40px;
}
.ant-modal-content .ant-modal-header {
  padding: 0;
  padding-bottom: 24px;
  margin: 0;
  border-bottom: 1px solid #E4E7F2;
  padding-right: 40px;
}
.ant-modal-content .ant-modal-header .ant-modal-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  color: #3B4168;
}
.ant-modal-content .ant-modal-body {
  padding-top: 18px;
  padding-bottom: 28px;
  padding-left: 0;
  padding-right: 0;
}
.ant-modal-content .ant-modal-footer {
  border-top: 1px solid #E4E7F2;
  padding: 24px 0 0 24px;
  display: flex;
  justify-content: flex-end;
}
.ant-modal-content .ant-modal-footer .ant-btn {
  padding: 12px 24px;
  height: initial;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-family: 'IBM Plex sans', sans-serif;
  border-radius: 4px;
}
.ant-modal-content .ant-modal-footer .ant-btn:disabled {
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
}
.ant-modal-content .ant-modal-footer .ant-btn-default {
  border-color: #0071CE;
  color: #0071CE;
}
.ant-modal-content .ant-modal-footer .ant-btn-primary {
  background: linear-gradient(94deg, #007BE0 -0.43%, #0058A0 54.69%);
}
/* DIVIDER */
.ant-divider.ant-divider-horizontal,
.ant-divider-horizontal.ant-divider-with-text {
  color: #6F7C98 !important;
  border-top-color: #E4E7F2 !important;
}
/* MESSAGE */
.ant-message {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 20px;
  right: 20px;
  top: auto;
  left: auto;
}
.ant-message-notice {
  text-align: end;
}
.ant-message-notice-content:has(.ant-message-success) {
  color: #FFFFFF;
  border-radius: 4px;
  background: linear-gradient(90deg, #51D54F 0%, #15B012 100%);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
.ant-message-notice-content:has(.ant-message-success) .anticon {
  font-size: 18px;
  color: #FFFFFF;
}
.ant-message-notice-content:has(.ant-message-error) {
  color: #FFFFFF;
  border-radius: 4px;
  background: linear-gradient(90deg, #FF5F5F 0%, #EB5757 100%);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
.ant-message-notice-content:has(.ant-message-error) .anticon {
  font-size: 18px;
  color: #FFFFFF;
}
/* SPACE */
.ant-space {
  flex-wrap: wrap;
}
.ant-pagination .ant-pagination-total-text {
  flex-grow: 1;
}
.ant-pagination .ant-pagination-prev button:not(:disabled),
.ant-pagination .ant-pagination-next button:not(:disabled),
.ant-pagination .ant-pagination-item a,
.ant-pagination .ant-select.ant-pagination-options-size-changer,
.ant-pagination .ant-select.ant-pagination-options-size-changer .ant-select-item-option {
  color: #3B4168;
}
.ant-pagination .ant-pagination-item-active a,
.ant-pagination .ant-pagination-item:hover a,
.ant-pagination .ant-pagination-prev button:hover:not(:disabled),
.ant-pagination .ant-pagination-next button:hover:not(:disabled) {
  color: #1890ff;
}
.ant-pagination .ant-select.ant-pagination-options-size-changer .ant-select-item-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-pagination .ant-select.ant-pagination-options-size-changer .ant-select-item-option.ant-select-item-option-active {
  color: #0071CE;
  background-color: transparent;
}
/* TABLE */
.ant-table-tbody .ant-table-cell {
  color: #3B4168;
}
.ant-table-container .ant-table-content .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent;
}
.ant-table-container .ant-table-content .ant-table-thead .ant-table-cell {
  background-color: transparent;
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #909EBB;
}
.ant-form-item-has-error .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content .ql-snow {
  border-color: #ff4d4f;
}
.ant-form-item-has-error .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content .ql-snow.ql-container {
  border-top-color: #ff4d4f;
  border-bottom-color: transparent;
}
/* DROPDOWN */
.ant-dropdown-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
.ant-dropdown-trigger.ant-dropdown-open {
  background-color: #E0F1FF;
}
.ant-dropdown-menu {
  display: inline-flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid #E4E7F2;
  background: #FFFFFF;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
}
.ant-dropdown-menu .ant-dropdown-menu-item {
  color: #3B4168;
  padding: 0;
}
.ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: transparent;
  color: #0071CE;
}
.ant-dropdown-menu .ant-dropdown-menu-item-disabled {
  color: #BDBDBD !important;
}
.ant-tabs {
  color: #3B4168;
  font-family: 'IBM Plex sans', sans-serif;
}
.ant-tabs .ant-tabs-nav .ant-tabs-nav-operations {
  display: none;
}
