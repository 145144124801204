@import "../../../../../../theme/core.less";

.admin-categories-pane-option .ant-select-item-option-content,
.admin-categories-pane .ant-select-selection-item,
.form-select .ant-select-selection-item {
  display: flex;
  vertical-align: center;
  align-items: center;
}

.admin_category_applications {
  min-height: 33px;
  color: #3b4168;
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  .ant-divider {
    margin: 12px 0;
  }
  &_more {
    display: flex;
    justify-content: center;
  }
}

.green {
  color: #15b012;
}

.gray {
  color: #909ebb;
}

.addBtn {
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-end;
}

.reportBtn {
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}

.admin-categories-pane .ant-list-item-meta-avatar {
  svg path {
    fill: #3b4168 !important;
  }
}

.modal {
    .ant-modal-content .ant-modal-body {
        padding-bottom: 0;
    }
    .admin-categories-radio-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;

        .admin-categories-radio-option {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 64px;
            height: 64px;
            border-radius: 8px;
            &:has(.ant-radio) {
                color: #909ebb;
                margin-right: 0;
                &:hover {
                    color: #0071ce;
                }
            }
            .ant-radio {
                display: none;
            }
            &:has(.ant-radio-checked) {
                background-color: #f5fbff;
                color: #0071ce;
                border: 1px solid #e4e7f2;
            }
        }
    }
}

