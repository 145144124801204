@import "../../../../theme/core.less";

.application-review {
  border-radius: 8px;
  padding-bottom: 40px;

  .catalog-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__success-block {
    text-align: center;
    color: @text-primary-color;
    margin-top: 17px;
    border-top: 1px solid @border-color;
    padding-top: 44px;

    .success-footer {
      display: flex;
      justify-content: center;
      padding-top: 32px;
    }

    .success-link {
      display: block;
      color: #3b4168;
      margin-top: 10px;

      a {
        color: #3b4168;
        margin-left: 5px;
      }
    }

    h4 {
      font-family: @font-headers;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      margin-top: 34px;
      color: @text-primary-color;
    }

    span:not(:last-child) {
      color: @text-primary-color;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      font-family: @font-content;
    }
  }

  &__modal-success {
    max-width: 707px !important;
    height: 483px !important;

    .ant-modal-body {
      padding: 0px !important;
    }

    .ant-modal-header {
      margin: 0px !important;
    }
  }

  &__order-modal {
    &__footer {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 8px;
    }

    &__header {
      padding-top: 24px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-radius: 16px;
      background-color: @colors-blue-10;

      p {
        color: @text-primary-color;
        font-family: @font-headers;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 0px !important;
      }

      span {
        color: @label-color;
        font-size: 14px;
        font-weight: 400;
        font-family: @font-content;
        line-height: 24px;
      }
    }

    &__card {
      border-radius: 10px !important;
      border: 1px solid @border-color;

      &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;

        & > p {
          margin: 0;
          font-family: @font-content;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        & > p:first-child {
          color: @text-secondary-color;
        }

        & > p:last-child {
          color: @el-black-color;
        }
      }

      & &__row:nth-child(2n) {
        background-color: @colors-blue-10;
      }

      .ant-card-body {
        padding: 0 !important;
      }

      .ant-card-head {
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        background-color: @el-gray-color;
        font-family: @font-content;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: @text-secondary;
      }
    }

    &__btn {
      width: 100%;

      &--cancel {
        border: none !important;
        color: @colors-blue-50 !important;
        background-color: @colors-blue-20 !important;
      }
    }

    &__title {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      color: @text-primary-color;
    }

    .ant-modal-content {
      border-radius: 12px;
      padding: 50px 48px;
      border-top: 4px solid @colors-blue-50;
    }

    .ant-collapse-header {
      color: @text-primary-color !important;
      font-size: 18px;
      font-family: @font-headers;
      font-weight: 500;
      line-height: 24px;
      border-top: none;
      padding-left: 0px !important;
      padding-top: 40px !important;
      padding-bottom: 40px !important;
    }

    .ant-collapse-content {
      padding-bottom: 40px;
      margin-top: -16px;

      .ant-collapse-content-box {
        padding: 0px !important;
      }
    }

    .ant-modal-header {
      margin: 0px !important;
    }

    .ant-modal-body {
      border-top: none;
      margin-top: 16px;
      padding: 0px !important;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }
  }

  &__info {
    display: flex;
    background: @bg-secondary;
    border-radius: 20px;

    .breakpoint(@breakpoint-tablet, {
      margin: 16px;
    });

    .breakpoint(@breakpoint-laptop, {
      height: auto;
      flex-direction: column;
    });

    .app-image {
      background-color: #ffffff;
      overflow: hidden;
      position: relative;
      min-width: 214px;
      width: 520px;
      max-width: 100%;
      min-height: 100%;
      font-size: 80px !important;
      border: 3px solid @bg-secondary !important;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center !important;
      .breakpoint(@breakpoint-laptop, {
        width: 100%;
        min-height: 200px
      });

      .img-wrapper {
        max-height: 520px;
        
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }


      &__category {
        position: absolute;
        left: 12px;
        top: 12px;
        max-width: calc(100% - 13px);
        padding: 5px 16px 7px 16px;
        border-radius: 100px;
        background: @status-green-color;
        color: @el-white-color;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        & > p {
          margin: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .app-image-logo-wrapper {
      width: 144px;
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: 17%;
      min-height: 100px;
    }

    .app-image-logo {
      background-color: #ffffff;
      overflow: hidden;
      position: relative;
      width: 520px;
      height: 320px;
      font-size: 80px !important;
      border-radius: 20px;
      .breakpoint(@breakpoint-laptop, {
        width: 100%;
        font-size: 20vw !important;
        max-height: 60vw;
      });
    }

    .app-content {
      width: 100%;
      padding: 40px 48px;
      display: flex;
      flex-direction: column;

      .breakpoint(@breakpoint-laptop, {
        width: 100%;
        padding: 24px;
      });

      .title {
        margin-bottom: 16px;
      }

      .content-text {
        min-height: 90px;
        .main-text {
          color: @text-primary-color;
          margin-bottom: 0;
        }
      }

      .content-actions {
        display: flex;
        flex-grow: 1;
        align-items: flex-end;
        justify-content: space-between;
        gap: 16px;
        flex-wrap: wrap;
        margin-top: 32px;

        .redirect {
          box-shadow: none;
          max-width: none;
          border: none;

          a {
            margin-right: 10px;
          }
        }

        div {
          display: flex;
          gap: 16px;
        }

        .content-action__button {
          svg path {
            fill: @text-secondary;
          }

          span {
            margin-left: 10px;
          }
        }

        .content-action__button--flat {
          color: @primary-color;
          border: none;
          background: #eff8fe;
        }

        .content-action__link {
          color: @primary-color;
        }

        @media screen and (max-width: 1024px) {
          display: block;
          button {
            min-width: 100% !important;
            margin-left: 0px !important;
          }

          > div {
            flex-direction: column;
          }

          .redirect {
            margin-top: 15px;
            border: 1px solid @primary-color;

            svg {
              display: none;
            }
          }
        }
      }
    }
  }

  .main-content {
    padding: 40px 164px;
    .breakpoint(@breakpoint-laptop, {
      padding: 0px 50px 50px 50px;
    });
    .breakpoint(@breakpoint-tablet-small, {
      padding: 0px 20px 20px 20px;
    });
  }

  .categories-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .categories-item {
    margin-right: 12px;
    margin-top: 32px;
    border-radius: 100px;

    &__title {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 3px 12px;
      margin: 0 !important;
    }

    .breakpoint(@breakpoint-tablet-small, {
      margin-right: 0px;
    });
  }

  &__tabs {
    background: @bg-secondary;
    padding: 40px;
    border-radius: 20px;

    .ant-tabs {
      &-nav {
        &-wrap {
          flex-grow: 1;
          .ant-tabs-nav-list {
            flex-grow: 1;
            background-color: #fafaff;
            border-radius: 100px;
            .ant-tabs-tab:not(.ant-tabs-tab-active) {
              color: #6f7c98 !important;
              &:hover {
                color: #40a9ff !important;
              }
            }
            .ant-tabs-tab {
              flex-grow: 1;
              &-btn {
                width: 100%;
                text-align: center;
              }
            }
          }
        }
      }
    }

    .character {
      display: flex;
      justify-content: space-around;
      align-content: center;
      margin-top: 60px;
      .breakpoint(@breakpoint-laptop, {
        display: block;
      });
    }

    .character-main-block {
      width: 100%;
      border: 1px solid @el-lines-color;
      border-radius: 10px;
      .breakpoint(@breakpoint-laptop, {
        margin-bottom: 32px;
      });

      .character-item {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        padding: 12px 24px;
        font-size: 16px;
        font-weight: 400;
        font-family: @font-content;
        line-height: 24px;
        color: @label-color;

        .general-title {
          color: @label-color;
          margin-right: 14px;
        }

        &:nth-child(2n) {
          background: #fafaff;
        }

        .breakpoint(@breakpoint-tablet, {
          flex-direction: column;
          align-items: flex-start; 
        });
      }

      .character-header {
        background: @el-gray-color;
        border-radius: 10px 10px 0 0;
        padding: 12px 24px;
      }

      &__title {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-transform: uppercase;
        color: @text-white-color;
      }

      &__mr {
        margin-right: 32px;
      }
    }

    .ql-editor {
      padding: 0 !important;
    }

    .ant-tabs-nav {
      margin-bottom: 0 !important;
    }

    .ant-tabs-tab {
      .h5();
      color: @text-primary-color;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      padding: 0 !important;
      border: none !important;
      background: none !important;
    }

    .ant-tabs-ink-bar {
      background: none;
    }

    .ant-tabs-tab-btn {
      padding: 8px 20px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: @el-white-color !important;
      background: @primary-color;
      border-radius: 100px;
      text-shadow: 0 0 0.25px currentcolor;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
      border: none;
    }

    .ant-tabs-content {
      .breakpoint(@breakpoint-laptop, {
        padding: 0;
      });

      .breakpoint(@breakpoint-tablet-small, {
        padding: 0;
      });
    }

    .inner-tab-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .inner-tab-content {
      margin-bottom: auto;
      flex: 2 1 auto;
      padding: 40px 0;
      .breakpoint(1292px, {
        padding: 20px 0;
      });

      .tab-title {
        .h5();
        color: @text-primary-color;
        font-size: 20px;
        font-weight: 600;
        padding-left: 15px;
      }

      .tab-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: @text-primary-color;
      }

      .tab-list-item {
        .main-text();
        &.hover-color:hover {
          background-color: #d4d4d440 !important;
          cursor: pointer;
          color: #40a9ff;
        }

        padding-left: 10px;
        padding-right: 10px;
        color: @text-primary-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-color: @border-color;

        &__name {
          display: flex;
          align-items: center;
        }

        & svg {
          width: 20px !important;
          height: 20px !important;
        }
        & svg path {
          stroke: @el-gray-color!important;
        }

        &__bg {
          background: #fafaff;
        }
      }
      .tab-list-item:nth-child(odd) {
        background-color: #fafaff !important;
      }

      .tab-list-item:nth-child(even) {
        background-color: none !important;
      }

      .tab-list-logo {
        width: 56px;
        height: 56px;
        border-radius: 12px;
        font-size: 18px;
        margin-right: 24px;

        .breakpoint(@breakpoint-tablet-small, {
          margin-right: 26px;
        });
      }

      &--width {
        max-width: none;
      }
    }

    .breakpoint(1292px, {
      padding: 40px 20px;
    });

    .breakpoint(@breakpoint-tablet-small, {
      margin: 0 16px;
    });
  }

  &__consultation {
    background-image: url("../../../../assets/images/catalog/bg-consultation.png");
    padding: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .breakpoint(@breakpoint-tablet-small, {
      display: block;
      margin: 40px 16px 0 16px;
    });

    .consultation-image {
      width: 20%;

      img {
        margin-top: -45px;
      }
    }

    .consultation-content {
      .header {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        color: @text-primary-color;
        .breakpoint(@breakpoint-tablet-small, {
          font-size: 20px;
        });
      }

      .description {
        color: @text-secondary-color;
        .breakpoint(@breakpoint-tablet-small, {
          font-size: 16px;
        });
      }
    }

    .consultation-actions {
      .consultation-action-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 32px 14px 28px;

        svg {
          margin-right: 8px;
        }
        .breakpoint(@breakpoint-tablet-small, {
          margin-top: 24px;
        });
      }
    }
  }
}

.analogs_desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: @text-primary-color;
  margin: 0;
  margin-bottom: 0;
  padding: 48px 0 8px;
}

