@import '../../theme/core.less';

.tag{
  padding: 3px 12px 5px 12px;
  border-radius: 100px;
  text-align: center;
}

.info-block {
  display: flex;
  align-items: center;
  gap: 8px;

  &__new {
    background: rgba(255, 113, 57, 0.06);
    color: #FF7139;
  }

  &__discount {
    background: rgba(150, 129, 242, 0.08);
    color: #7B61FF;
  }

  &__academic {
    background: rgba(64, 186, 33, 0.06);
    color: #40BA21;
  }

  &__special {
    color: #17B69C;
    background: rgba(23, 182, 156, 0.08);

 }

  &__free {
    background: rgba(255, 113, 57, 0.08);
    color: #FF7139;
  }

  .badge-count {
    background: @bg-primary;
    padding: 2px 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: @el-gray-color;
    border-radius: 8px;
  }
}

