@import "../../../../theme/core.less";

.applications-catalog {
  .empty-apps {
    .breakpoint(@breakpoint-tablet, {
      margin: 0 16px;
    });
  }

  &__brief-block {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    min-height: 250px;
    background-color: @bg-secondary;
    border-radius: 20px;
    margin-bottom: 48px;
    .breakpoint(@breakpoint-laptop, {
      flex-direction: column-reverse;
    });

    .breakpoint(@breakpoint-tablet, {
      flex-direction: column-reverse;
      margin: 16px 16px 0;
    });
  }

  &__brief-text {
    .main-text();
    color: @text-secondary-color;
    flex-grow: 1;

    .content {
      padding: 40px;
      display: flex;
      flex-direction: column;
      height: 100%;

      .breakpoint(@breakpoint-laptop, {
      padding: 24px !important;
    });

      .title {
        margin: 16px 0;
      }

      .buy-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding: 12px 24px !important;
        .breakpoint(@breakpoint-tablet, {
          min-width: 100%;
        });
        &.email {
          background: #f5f6f8 !important;
          color: #3b4168;
        }
      }

      & .ql-editor {
        padding: 0;
        .breakpoint(@breakpoint-laptop, {
          max-height: initial;
        });
      }

      & .content-text {
        margin-bottom: 30px;

        .breakpoint(@breakpoint-laptop, {
      margin-bottom: 24px;
    });
      }
    }

    .breakpoint(@breakpoint-tablet, {
      width: auto;
    });
  }

  .vendor-image {
    overflow: hidden;
    border-radius: 20px;
    min-width: 360px;
    max-width: 360px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-basis: 100%;

    .img-wrapper {
      max-height: 520px;

      img {
        max-height: 400px;
        max-width: 100%;
      }
    }

    .breakpoint(@breakpoint-laptop, {
      min-width: initial;
      max-width: 100%;
      justify-content: center;
    });
  }

  .vendor-image-logo {
    background-color: #ffffff;
    overflow: hidden;
    position: relative;
    min-width: 520px;
    max-width: 40%;
    min-height: 100%;
    font-size: 80px;
    border-radius: 20px;

    .breakpoint(@breakpoint-laptop, {
      height: 320px;
      min-width: initial;
      max-width: initial;
      font-size: 20vw;
      max-height: 60vw;
    });

    &-wrapper {
      border-radius: 20px !important;
      border: 3px solid #ffffff !important;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-family: "Montserrat", sans-serif;
    }
  }

  &__list {
    & .subtitle {
      font-size: 20px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 28px !important;
      margin-bottom: 0 !important;
    }

    .category {
      &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .breakpoint(1292px, {
          display: block;
        });
        .breakpoint(@breakpoint-tablet, {
          margin: 40px 16px 0;
        });
      }

      &-list {
        display: flex;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        white-space: nowrap;
        overflow-x: auto;
        .breakpoint(1292px, {
          margin-top: 24px;
          padding-bottom: 12px;
        });

        li {
          padding: 4px 16px 6px 16px;
          border-radius: 100px;
          background: @bg-secondary;
          color: @label-color;
          margin-right: 8px;

          &:hover {
            cursor: pointer;
          }
        }

        &__active {
          background: @brand-primary-color !important;
          color: @text-white-color !important;
        }
      }
    }
  }
}

