@import "../../theme/core.less";

.ooc-pagination-v2 {
    font-family: @font-content;
    min-width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__left-total,
    .ant-pagination {
        color: @text-help-color;
    }

    &__left-total {
        margin: 6px 0 8px;
        .main-text();

        &--hidden > span {
            display: none;
        }
    }

    .ant-pagination {
        display: flex;
        align-items: center;

        &::before {
            order: -2;
            content: attr(data-options-label);
            color: @text-help-color;
            font-size: 16px;
        }

        &-item {
            display: none !important;
        }

        &-options {
            order: -1;

            .ant-select-selector {
                border-radius: 10px !important;
                height: 34px !important;
                display: flex;
                align-items: center;
                border: none !important;
                box-shadow: none !important;
                color: @text-link-color;
                font-size: 16px;
            }

            .ant-select-arrow {
                color: @text-link-color;
            }

            .ant-select-dropdown {
                padding: 0;
                border-radius: 8px;

                .ant-select-item-option {
                    padding-left: 16px;
                }

                .ant-select-item-option-active {
                    // hover
                    background-color: @primary-color-o-10;
                }

                .ant-select-item-option-selected {
                    // selected
                    background-color: initial;
                    color: @primary-color;
                }
            }
        }

        &-total-text {
            margin-left: 40px;
            font-size: 16px;
        }

        &-prev, &-next {
            margin-left: 14px !important;
        }

        &-prev[aria-disabled="true"],
        &-next[aria-disabled="true"] {
            button {
                color: @bc-color;
            }
        }

        &-prev[aria-disabled="false"],
        &-next[aria-disabled="false"] {
            button {
                color: @primary-color;
            }
        }

        &-jump-next, &-jump-prev {
            display: none;
        }
    }
}

