@import "../../../../theme/core.less";

.need-consultation-form {
  margin-top: 40px;

  &__success-block {
    text-align: center;
    color: @text-primary-color;
    margin-top: 40px;

    .success-footer {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid @border-color;
      padding-top: 32px;
      margin-top: 32px;
    }

    h4{
      font-family: @font-headers;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      margin-top: 50px;
      color: @text-primary-color;
    }
    span:not(:last-child){
      color: @text-primary-color#3B4168;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      font-family: @font-content;
    }
  }

  &__modal-success {
    max-width: 707px !important;
    height: 483px !important;
  }

  &__modal-width{
    max-width: 662px !important;
    max-height: 815px !important;
    top: 20px !important;

      .breakpoint(@breakpoint-tablet-small, {
        .ant-modal-title{
          padding-top: 25px !important;
          padding-left: 25px !important;
        }
        .ant-modal-close {
          top: 8px
        }
      });

  .breakpoint(@breakpoint-laptop-large, {
    max-width: 800px !important;
    max-height: 615px !important;
  });
  .breakpoint(@breakpoint-laptop, {
    max-width: 700px !important;
    max-height: 515px !important;
  });
  .breakpoint(@breakpoint-tablet, {
    max-width: 600px !important;
    max-height: 515px !important;
  });
  .breakpoint(@breakpoint-tablet-small, {
    max-width: 500px !important;
    max-height: 515px !important;
  });


    .ant-modal-body{
      padding: 0;
      padding-top: 32px;
      .breakpoint(@breakpoint-tablet-small, {
        padding: 0 !important;
      });
    }
    .ant-modal-content{
      border-radius: 10px;
      padding: 0 48px;
      .breakpoint(@breakpoint-tablet-small, {
        padding: 0 !important;
      });
        .ant-modal-close{
          right: 14px;
          .breakpoint(@breakpoint-tablet-small, {
            right: 0px;
          });
        }
        .ant-modal-header {
          margin: 0px ;
        }
    }
  }

  &__content-block {
    padding-top: 32px;

    .breakpoint(@breakpoint-tablet-small, {
      margin-top: 15px;
      padding-top: 0px;
      border: none;
    });
    .full:not(:last-child){
      margin-right: 20px;

    .breakpoint(@breakpoint-tablet-small, {
      margin: 0 !important;
    });
    }

    .modal-title {
      font-family: Montserrat, sans-serif;
      color: @text-primary-color;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      padding-bottom: 12px;
      margin-bottom: 0;
    }

    .full{

      label{
        color: @text-primary-color;
        font-family: @font-content;
        font-weight: 400;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
      }
      .breakpoint(@breakpoint-tablet-small, {
        width: 100%;
    });
    }
    .breakpoint(@breakpoint-tablet-small, {
        .footer-block button:not(:last-child){
            background: @bg-primary;
          }
       });

    .footer-block {
      display: flex;
      justify-content: center;
      padding: 16px 0 40px 0;
      .button-valid {
        background: @primary-color;
        color: @text-secondary;
        border-radius: 4px;
      }
      .button-disabled {
        background: @text-help-color;
        color: @text-secondary;
        border-radius: 4px;
        border: 1px solid @text-help-color !important;
      }
      button:not(:last-child){
        margin-right: 10px;
        border: 1px solid @primary-color !important;
        color: @primary-color;
      }
      .breakpoint(@breakpoint-tablet-small, {
        display: flex;
        flex-wrap: wrap-reverse;
        padding-top: 0px;
        padding-left: 20px;
        padding-right: 20px;
        border: none;
        background: @bg-primary;

        button{
          margin-bottom: 0;
          min-width: 100%;
          margin-right: 0px !important;
        }
       });

      .ooc-button-v2.ant-btn {
        width: 100%!important;
      }
    }

    .main-content {
      padding: 32px 0 6px 0;

      .breakpoint(@breakpoint-tablet-small, {
        margin-bottom: 0px;

      });

      .checkbox-block {
        border-radius: 8px;
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center;
        padding: 6px 16px 6px 16px;

        label{
          font-size: 16px;
          font-weight: 400;
          font-family: @font-content;
          color: @text-primary-color !important;
        }
        a {
          margin-left: 5px ;

        }
      }

      .data-block {
        .ant-row {
           display: block !important;
        }
        .data-title{
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: @text-primary-color;
        }
      }
    }
    .information-block {
      padding: 10px 20px;
      .breakpoint(@breakpoint-tablet-small, {
       border-radius: 0px;
       display: block;
       text-align: center;
       p {
        font-size: 16px !important;
       }
       span {
        font-size: 12px !important;
       }
      });

      p {
        font-family: @font-headers;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: @text-white-color;
      }
      span {
        font-family: @font-content;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: @text-white-color;
      }
    }
  }
}

