@import "../../theme/core.less";

.ooc-table {
    .ant-table {
        border-radius: @step-2;
    }

    .ant-table-container table > thead > tr:first-child th:first-child {
        border-top-left-radius: @step-2;
    }

    .ant-table-container table > thead > tr:first-child th:last-child {
        border-top-right-radius: @step-2;
    }

    .ant-table-thead > tr > th {
        background-color: @bg-secondary;
    }

    .ant-table-pagination{
        margin-right: @step-5;
        .ant-pagination-item {
            display: none!important;
        }
        .ant-pagination-jump-next{
            display: none!important;
        }
        .ant-pagination-jump-prev{
            display: none!important;
        }
        .ant-select-selection-item{
            min-width: 67px;
        }
    }

    .ant-table-column-sorter-inner {
        .ant-table-column-sorter-up, 
        .ant-table-column-sorter-down {
            // display: none;

            &.active {
                display: inline-block;
                color: @bc-color;
            }
        }
    }
}

