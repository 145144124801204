@import "../../../../theme/core.less";

.ant-tooltip-inner {
  padding: 4px 6px 4px 6px !important;
  border-radius: 4px !important;
}

/*.ant-modal-wrap {
  bottom: 10px !important;

  .ant-modal {
    width: 662px !important;
  }
}*/

.icon-category-block {
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
}

.app-category-count {
  color: @el-gray-color;
}



.search-input__dropdown {
  border-radius: 16px !important;
  border-width: 1px !important;
  border-color: @border-color !important;
  border-style: solid;
}

.search-input__options {
  padding: 8px 20px !important;
  font-family: 'IBM Plex Sans' !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: left;
  color: @text-primary-color !important;
}

.ant-select-item-option-selected.search-input__options {
  color: @primary-color !important;
}

.vendors-catalog {
  margin-top: 32px;

  &__success-block {
    text-align: center;
    color: @text-primary-color;
    margin-top: 40px;

    .success-footer {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid @border-color;
      padding-top: 32px;
      margin-top: 32px;
    }

    h4 {
      font-family: @font-headers;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      margin-top: 50px;
      color: @text-primary-color;
    }

    span:not(:last-child) {
      color: @text-primary-color #3B4168;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      font-family: @font-content;
    }
  }

  &__modal-success {
    max-width: 707px !important;
    height: 483px !important;
  }

  &__modal-width {
    max-width: 800px !important;
    max-height: 815px !important;
    top: 20px !important;

    .breakpoint(@breakpoint-tablet-small, {
      .ant-modal-title {
        padding-top: 25px !important;
        padding-left: 25px !important;
      }
      .ant-modal-close {
        top: 8px
      }
    });

    .breakpoint(@breakpoint-laptop-large, {
      max-width: 800px !important;
      max-height: 615px !important;
    });
    .breakpoint(@breakpoint-laptop, {
      max-width: 700px !important;
      max-height: 515px !important;
    });
    .breakpoint(@breakpoint-tablet, {
      max-width: 600px !important;
      max-height: 515px !important;
    });
    .breakpoint(@breakpoint-tablet-small, {
      max-width: 500px !important;
      max-height: 515px !important;
    });


    .ant-modal-body {
      padding: 0;
      padding-top: 32px;
      .breakpoint(@breakpoint-tablet-small, {
        padding: 0 !important;
      });
    }

    .ant-modal-content {
      border-radius: 10px;
      padding: 0 48px;
      .breakpoint(@breakpoint-tablet-small, {
        padding: 0 !important;
      });

      .ant-modal-close {
        right: 14px;
        .breakpoint(@breakpoint-tablet-small, {
          right: 0px;
        });
      }

      .ant-modal-header {
        margin: 0px;
      }
    }
  }

  &__content-block {
    padding-top: 32px;
    .breakpoint(@breakpoint-tablet-small, {
      margin-top: 15px;
      padding-top: 0px;
      border: none;
    });

    .modal-title {
      font-family: Montserrat, sans-serif;
      color: @text-primary-color;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      padding-bottom: 12px;
      margin-bottom: 0;
    }

    .modal-desc {
      margin-bottom: 0;
      color: @text-secondary-color;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .full:not(:last-child) {
      margin-right: 20px;

      .breakpoint(@breakpoint-tablet-small, {
        margin: 0 !important;
      });
    }

    .full {

      label {
        color: @text-primary-color;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .breakpoint(@breakpoint-tablet-small, {
        width: 100%;
      });
    }

    .breakpoint(@breakpoint-tablet-small, {
      .footer-block button:not(:last-child) {
        background: @bg-primary;
      }
    });


    .footer-block {
      display: flex;
      justify-content: center;
      padding: 16px 0 40px 0;

      .button-valid {
        background: @primary-color;
        color: @text-secondary;
        border-radius: 4px;
      }

      .button-disabled {
        background: @text-help-color;
        color: @text-secondary;
        border-radius: 4px;
        border: 1px solid @text-help-color !important;
      }

      button:not(:last-child) {
        margin-right: 10px;
        border: 1px solid @primary-color !important;
        color: @primary-color;
      }

      .breakpoint(@breakpoint-tablet-small, {
        display: flex;
        flex-wrap: wrap-reverse;
        padding-top: 0px;
        padding-left: 20px;
        padding-right: 20px;
        border: none;
        background: @bg-primary;

        button {
          margin-bottom: 0;
          min-width: 100%;
          margin-right: 0px !important;
        }
      });

      .ooc-button-v2.ant-btn {
        width: 100% !important;
      }
    }

    .main-content {
      padding: 32px 0 6px 0;
      .breakpoint(@breakpoint-tablet-small, {
        margin-bottom: 0px;
      });

      .checkbox-block {
        background-color: rgba(0, 113, 206, 0.05);
        border-radius: 8px;
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center;
        padding: 6px 16px 6px 16px;

        label {
          font-size: 16px;
          font-weight: 400;
          font-family: @font-content;
          color: @text-primary-color !important;
        }

        a {
          margin-left: 5px;

        }
      }

      .data-block {
        .data-title {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: @text-primary-color;
        }

        .ant-row {
          display: block !important;
        }

        .PhoneInput {
          width: 100%;
          height: 44px;
          padding: 12px 16px 12px 16px;
          border: 1px solid @el-lines-color;
          border-radius: 8px;
          font-family: 'IBM Plex sans', sans-serif;
          font-style: normal;
          font-size: 16px;
          line-height: 20px;
          color: #3B4168;
          margin-bottom: 24px;
        }

        .PhoneInputInput {
          border: none !important;
        }

        .PhoneInput:focus-within {
          border-color: #007BFF;
          box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
          transition: 0.3s all;
        }

        .PhoneInputInput:focus {
          outline: none;
        }

        .error-validate__field {
          position: relative;
        }

        .error-validate__phone {
          position: absolute;
          top: -25px;
          color: #ff4d4f;
        }

      }
    }

    .information-block {
      padding: 10px 20px;
      .breakpoint(@breakpoint-tablet-small, {
        border-radius: 0px;
        display: block;
        text-align: center;
        p {
          font-size: 16px !important;
        }
        span {
          font-size: 12px !important;
        }
      });

      p {
        font-family: @font-headers;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: @text-white-color;
      }

      span {
        font-family: @font-content;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: @text-white-color;
      }
    }
  }

  .special-block__specialColorSoft {
    background: linear-gradient(90deg, #71d071 30%, #54c77e 92%);
  }

  .special-block__specialColorHits {
    background: linear-gradient(90deg, #84c5f6 0%, #4c9bdd 92%);;
  }

  .special-block__specialColorPartner {
    background: linear-gradient(90deg, #9686f3 5%, #9c7ef4 92%);

    &:hover {
      cursor: pointer;
    }
  }

  &__special-block {
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    height: 200px;
    width: 100%;

    .description-block p {
      .breakpoint(@breakpoint-mobile-large, {
        font-size: 16px!important;
      });
    }

    .details-block {
      display: flex;
      padding-bottom: 20px;

      span {
        color: @text-white-color;
        font-size: 16px;
        padding-left: 20px;
      }

      svg {
        margin-left: 10px;
      }

    }

    .special-block-icon {
      display: flex;
      overflow: hidden !important;


      .breakpoint(@breakpoint-tablet-small, {
        align-items: center !important;
      });

      .app-mobile {
        display: none;
        .breakpoint(@breakpoint-tablet-small, {
          display: block;
          width: 100% !important;
        })
      }

    }

    @media screen and (max-width: 1600px) {
      height: 200px;
    }

    @media screen and (max-width: 1600px) {
      img {
        width: 100% !important;
      }
    }

    p {
      font-size: 20px !important;
      width: 191px;
      color: @text-white-color;
      font-family: @font-headers !important;
      line-height: 28px;
      font-weight: 600 !important;
      font-style: normal;
      padding: 24px 0 24px 24px;

      .breakpoint(@breakpoint-mobile-large, {
        font-size: 16px;
      });
    }

    @media screen and (max-width: 530px) {
      img {
        width: 100% !important;
      }
    }

  }

  &__search-block {
    flex-direction: column;
    background-color: @bg-secondary;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    padding: 32px 40px 40px 40px;
    overflow: hidden;
    border-radius: 20px;
    .breakpoint(@breakpoint-laptop-large, {
      padding: 25px;
    });
    .breakpoint(@breakpoint-tablet, {
      margin: 0 16px;
    });

    .title {
      margin-bottom: 24px !important;
    }

    .search-input {
      display: inline-flex;
      align-items: center !important;
      width: 100% !important;
      border-radius: 8px !important;
      border: 1px solid @el-lines-color !important;
      background: #FAFAFF !important;
      height: 48px !important;
      .breakpoint(@breakpoint-laptop-large, {
        display: block;
        height: auto !important;
        background: none !important;
        border: none !important;
      });

      .divide-border {
        width: 1px;
        background-color: @border-color;
        height: 20px !important;
        .breakpoint(@breakpoint-laptop-large, {
          display: none;
        });
      }

      .search-type {
        width: 290px;
        color: @text-primary-color;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        font-family: @font-content;

        .breakpoint(@breakpoint-laptop-large, {
          width: 100%;
          background: none !important;
          margin-bottom: 20px;
        });

        &__item {
          color: #3B4168 !important;
        }

        .ant-select-selector {
          border-radius: 8px 0px 0px 8px !important;
          border-right: 0px;
          display: flex !important;
          border: none !important;
          padding-left: 24px !important;
          height: 45px !important;
          align-items: center;
          background-color: #FAFAFF !important;
          box-shadow: none !important;
          .breakpoint(@breakpoint-laptop-large, {
            border: 1px solid @colors-blue-50 !important;
            background-color: transparent !important;
            border-radius: 12px !important;
          });
        }

        .ant-select-arrow {
          margin-right: 5px;
          transition: 0.3s all !important;
          transform: rotate(0deg) !important;
          .breakpoint(@breakpoint-laptop-large, {
            margin-right: 0;
          });
        }

        .ant-select-selection-item {
          margin-right: 10px !important;
          .breakpoint(@breakpoint-laptop-large, {
            margin-right: 0 !important;
          });
        }

        .ant-select-dropdown {
          border-radius: 10px !important;
        }

        .ant-select-dropdown-menu-item {
          border-radius: 10px !important;
        }

      }

      .search-type.ant-select-open .ant-select-arrow {
        transform: rotate(180deg) !important;
      }

      .ant-select-single.ant-select-open .ant-select-selection-item {
        color: @text-primary-color !important;
      }

      .ooc-dropdown-search {
        width: 77%;

        .breakpoint(@breakpoint-laptop-large, {
          width: 100%;
        });

        .ant-select-selector {
          border-radius: 0px 8px 8px 0px !important;
          border: none !important;
          height: 45px !important;
          display: flex;
          align-items: center;
          padding-left: 24px !important;
          background-color: #FAFAFF !important;
          .breakpoint(@breakpoint-laptop-large, {
            border: 1px solid #d9d9d9 !important;
            background-color: transparent !important;
            border-radius: 12px !important;
          });
        }

        .ant-select-arrow svg path {
          .breakpoint(@breakpoint-laptop-large, {
            fill: #3B4168;
          });
        }
      }
    }

    .search-img {
      display: none;
      margin: 30px -50px -190px auto;
    }

    .search-input-img {
      width: 60px !important;
    }


    .breakpoint(@breakpoint-tablet, {
      background-image: none;
      padding-top: 30px;
    });
    .breakpoint(@breakpoint-mobile-large, {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 30px;
    });

    .categories-mobile-block {
      display: none;

      .categories-title {
        color: @label-color;
        font-family: @font-content;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      .breakpoint(1290px, {
        display: block;
      });
    }

    .category-select-mobile {
      min-width: 100%;
      margin-bottom: 20px;

      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }

      .ant-select-selector {
        border-radius: 12px !important;
        height: 44px !important;
        padding: 12px 16px 12px 16px !important;

        .select-title {
          color: @text-primary-color;
          font-size: 14px;
          font-weight: 400;
          font-family: @font-content;
          line-height: 20px;

          .app-category-count {
            color: @el-gray-color;
          }
        }
      }
    }

  }

  &__promos {
    display: flex;
    border-radius: 0px 0px 8px 8px;
    padding-top: 22px;
    flex-wrap: wrap;
    gap: 16px;
    .breakpoint(@breakpoint-tablet, {
      margin: 0 16px;
      flex-direction: column;
    });
    & > a {
      flex-basis: 20%;
      flex-grow: 1;
      .breakpoint(1500px, {
        flex-basis: 45%;
      });
      .breakpoint(768px, {
        flex-basis: 100%;
      });
    }
    .banner-link {
      border-radius: 20px;
      overflow: hidden;

      .banner-wrapper {
        height: 100%;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 200px;

        & > img {
          width: 100%;
        }
      }
    }
  }

  .promo-card {
    min-height: 100%;
    height: 100%;

    @media screen and (max-width: 1600px) {
      height: 50%;
    }
  }

  @media screen and (max-width: 1600px) {
    .promo-card {
      height: 148px;
    }
  }

  &__footer-promos {
    border-radius: 8px;
    @media screen and (max-width: 1290px) {
      padding: 12px;
    }

    .ant-col {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &__subtitle {
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 20px;
      line-height: 28px;
      color: #3B4168;
      margin: 0;
      padding-bottom: 24px;
    }

    .promo-card__icon {
      .breakpoint(@breakpoint-tablet-small, {
        align-items: center !important;
      });

      .mobile {
        .breakpoint(@breakpoint-tablet-small, {
          display: block;
        })
      }
    ;

      .laptop {
        .breakpoint(@breakpoint-tablet-small, {
          display: none;
        })
      }
    ;
    }

    .breakpoint(@breakpoint-laptop-large, {
      padding: 0;
    });
    .breakpoint(@breakpoint-mobile-large, {
      padding: 50px 20px;
    });


    .promo-card__label {
      font-size: 24px !important;
      width: 100%;
      font-family: @font-headers !important;
      line-height: 28px;
      font-weight: 600 !important;
      font-style: normal;

      @media screen and (max-width: 1900px) {
        font-size: 18px !important;
      }
    }
  }


  &__list {
    padding: clamp(20px, 6vw, 60px) 0;
    .breakpoint(@breakpoint-tablet, {
      margin: 0 16px;
    });

    .subtitle {
      margin-bottom: 24px !important;
      font-size: 20px !important;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      .breakpoint(1326px, {
        display: block;
      });
    }

    &-header__filter {
      display: flex;
      list-style: none;
      overflow-x: auto;
      .breakpoint(1326px, {
        padding-left: 0;
      });

      & li {
        padding: 4px 16px 6px 16px;
        border-radius: 100px;
        background: #FFF;
        color: @text-secondary-color;
        margin-left: 8px;

        &:hover {
          color: @text-link-color;
          cursor: pointer;
        }
      }
    }

  }
}

