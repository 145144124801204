@import "../../theme/core.less";

.page-wrapper {
  width: 100%;
  /*height: 100%;*/
  background: @bg-primary;

  &__container {
    height: calc(100% - @nav-height);
    display: flex;
    position: relative;
  }

  &__main-content {
    width: 100%;
    /*overflow: auto;*/
    position: relative;
    transition: all 0.3s, outline 0s;

    .breakpoint(@breakpoint-mobile-large, {
      padding: 0;
    });
  }

  &__catalog-page-content {
    width: 100%;
    overflow: auto;
    position: relative;
    padding-right: 40px;

    transition: all 0.3s, outline 0s;

    @media screen and (max-width: 756px) {
      padding: 16px;
    }

    .breakpoint(@breakpoint-mobile-large, {
      padding: 0;
    });
  }

  .mobile-nav-drawer {
    position: absolute;

    .ant-drawer-body {
      padding: 0;
    }

    transition: all 0.3s, outline 0s;

    &.small {
      min-width: 60px !important;
      width: 60px !important;
      transition: all 0.3s, outline 0s;
    }

    &.ant-drawer-open .ant-drawer-mask {
      background: rgba(228, 231, 242, 0.4);
      backdrop-filter: blur(10px);
    }
  }
}

@media screen and (max-width: 756px) {
  .page-wrapper {
    &__main-content {
      & > div {
        padding-left: 0px;
        transition: all 0.3s, outline 0s;
      }
    }
  }
}

.other-page {
  padding: 0 clamp(0px, 2.2vw ,40px);
  max-width: 1624px;
  margin: 0 auto;

  .breakpoint(@breakpoint-laptop-large, {
    display: block !important;
  });
  .breakpoint(@breakpoint-mobile-large, {
    padding: 0;
  });

  .content {
    border-radius: 8px;
    width: 100%;
  }

  .subtitle {
    .h3();
    color: @text-primary-color;
    margin-bottom: 48px;

    .breakpoint(@breakpoint-mobile-large, {
      .mobile-h3();
      margin-bottom: 28px;
    });
  }

  .main-text {
    .main-text();
    color: @text-secondary-color;
  }

  .content-grid {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;

    .breakpoint(1292px, {
      grid-template-columns: repeat(2, 1fr);
    });
    .breakpoint(799px, {
      grid-template-columns: repeat(1, 1fr);
    });
  }
}

.catalog-wrapper {
  padding: 0 clamp(0px, 2.2vw ,40px);
  max-width: 1624px;
  margin: 0 auto;
  display: flex;

  .breakpoint(@breakpoint-tablet, {
    padding: 0;
    margin-left: 0;
    margin-right: 0;

  });

  .content-wrapper {
    border-radius: 8px;
    padding-left: clamp(12px, 2vw, 32px) !important;
    flex: 1;
    .breakpoint(1290px, {
      width: 100%;
      padding: 0 !important;
    });
  }

  .subtitle {
    .h3();
    color: @text-primary-color;
    margin-bottom: 48px;

    .breakpoint(@breakpoint-mobile-large, {
      .mobile-h3();
      margin-bottom: 28px;
    });
  }

  .main-text {
    .main-text();
    color: @text-secondary-color;
  }

  .content1-wrapper-grid {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;

    .breakpoint(@breakpoint-laptop, {
      grid-template-columns: repeat(2, 1fr);
    });
    .breakpoint(@breakpoint-tablet-small, {
      grid-template-columns: 1fr;
    });
  }
}

