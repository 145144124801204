@import '../../theme/core.less';

.card-logo-bordered {
    &__inner {
        border-radius: 20px !important;
        border: 3px solid @bg-secondary!important;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-family: @font-headers;
        &--blue-light {
            background: @el-hover-2-color;
            color: @el-active-color;
        }

        &--blue {
            background: @colors-helsinki-light;
            color: @colors-helsinki;
        }

        &--turquoise {
            background: @colors-tokyo-light;
            color: @colors-tokyo;
        }

        &--orange {
            background: @status-warning-light-color;
            color: @status-warning-color;
        }

        &--green {
            background: @status-green-light-color;
            color: @status-green-color;
        }
    }

    &__circle {
        border-radius: 50% !important;
        width: 63%;
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-family: Montserrat;
        background-color: rgba(0, 0, 0, 0.05) !important;
    }
}

