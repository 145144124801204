@import '../../theme/core.less';

.rating-panel {
  display: flex;
  align-items: center;
  min-height: 28px;

  & > svg {
    margin-right: 8px;
    path {
      stroke: @el-gray-color;
    }
  }

  &__amount {
    margin-bottom: 0;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: @text-primary-color;
    margin-left: 8px;
  }

  .star-filled path {
      stroke: @primary-color;
      fill: @primary-color;
  }

  .star-empty path {
    stroke: #909EBB;
  }

}

