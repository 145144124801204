@import "../../../../theme/core.less";

.select-catalog {
  &__catalog-content {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .breakpoint(@breakpoint-tablet-small, {
      flex-wrap: nowrap;
      max-width: none;
      overflow-x: auto;
      word-break: break-all;
      margin: 16px;
    });

    &-button {
      border: none;
      padding: 4px 16px 6px 16px;
      border-radius: 100px;
      background: #FFF;
      color: #6F7C98;
      margin-left: 8px;
      margin-bottom: 8px;
      cursor: pointer;
      .breakpoint(@breakpoint-tablet-small, {
        padding: 3px 12px 5px 12px;
        white-space: nowrap;
      });

      &--selected {
        background: @brand-primary-color;
        color: @text-white-color;

        svg path{
          fill: @bg-secondary;
        }
      }

      i {
        margin-right: 5px;
        display: flex;
        align-items: center;
      }
    }
  }
}

