@import '../../theme/core.less';

.ooc-text-editor {
  &.quill {
    display: flex;
    flex-direction: column-reverse;
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid @border-color;
  }

  .ql-container {
    border: 1px solid @border-color;
    border-radius: 4px 4px 0 0;
    min-height: 100px
  }

  .ql-toolbar {
    background: @el-active-2-color;
    border: 1px solid @border-color;
    border-radius: 0 0 4px 4px;
  }

  .ql-tooltip {
    top: 0 !important;
    left: 0 !important;
  }
}

