@import "../../theme/core.less";

.ooc-pagination {
    font-family: @font-content;
    min-width: fit-content;

    .ant-pagination {
        display: flex;
        align-items: center;

        &::before {
            order: -2;
            content: attr(data-options-label);
            margin-right: 16px;
        }

        &-item {
            display: none !important;
        }

        &-options {
            order: -1;

            .ant-select-selector {
                padding: 0 16px !important;
                border-radius: 10px;
                height: 34px !important;
                display: flex;
                align-items: center;
                border: none;
                box-shadow: none !important;
            }

            .ant-select-dropdown {
                padding: 0;
                border-radius: 8px;

                .ant-select-item-option {
                    padding-left: 16px;
                }

                .ant-select-item-option-active {
                    // hover
                    background-color: @primary-color-o-10;
                }

                .ant-select-item-option-selected {
                    // selected
                    background-color: initial;
                    color: @primary-color;
                }
            }
        }

        &-total-text {
            margin-left: 64px;
        }

        &-prev, &-next {
            margin-left: 24px !important;
        }

        &-prev[aria-disabled="true"], 
        &-next[aria-disabled="true"] {
            button {
                color: @bc-color;
            }
        }

        &-prev[aria-disabled="false"], 
        &-next[aria-disabled="false"] {
            button {
                color: @primary-color;
            }
        }

        &-jump-next, &-jump-prev {
            display: none;
        }
    }
}

