@import "../../theme/core.less";

.feedback-info {
  display: flex;
  flex-direction: column;
  gap: 24px; 

  &__rating {
    display: flex;
    justify-content: center;
    padding: 24px;
    gap: 16px;
    background-color: #F5F6F8;

    .star {
      width: 32px;
      height: 32px;
      transition: color 100ms;
      
      &.star-empty {
        color: transparent;
        use {
          stroke: @border-color;
          stroke-width: 5;
        }
      }

      &.star-filled {
        color: @el-gray-color;
      }
    }
  }

  &__authorName {
    display: flex;
    gap: 24px;
  }

  .infoFieldInput {
    background-color: #F5F6F8;
    border-radius: 4px;
    padding: 12px 16px;
  }

  &__infoField {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex-grow: 1;

    &-label {
      font-family: "IBM Plex Sans";
      font-size: 14px;
      line-height: 20px;
      color: #6F7C98;
      flex-grow: 1;
    }

    &-value {
      color: #3B4168;
      font-family: "IBM Plex Sans";
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__avatar {
    align-items: center;
    display: flex;
    background-color: #F5F6F8;
    padding: 12px 24px;
    border-radius: 8px;
    gap: 24px;

    .img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 60px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      .img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
