@import "../../../../theme/core.less";

.draft-related-app {
  padding: 16px;
  cursor: default;
}

.table-sort-header {
  display: flex;
  gap: 8px;
  align-items: center;
}

.related-app {
  font-family: "IBM Plex Sans";
  font-size: 16px;
  line-height: 20px;
  color: #3B4168;
  .anticon {
    display: none;
  }


  &-link {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding: 16px;
  }

  &-divider {
    margin: 0 !important;
  }

  &:hover {
    .related-app-link {
      background-color: #F5FBFF;
    }
    .anticon {
      display: block;
    }
  }

}

.admin_category_applications_more {
  margin-top: 16px;
}

