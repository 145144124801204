@import "../../../../../../theme/core.less";


.report-author {
  color: #3B4168;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.report-modal {
  .ant-modal-content .ant-modal-footer {
    padding: 16px 0 0;
    justify-content: flex-start;
  }
}

.centred-full {
  display: flex;
  align-items: center;
  justify-content: center;
}
