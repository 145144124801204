@import "../../theme/core.less";

.catalog-page {
  .breakpoint(@breakpoint-laptop-large, {
        display: block !important
    });
   

  &__content {
    border-radius: 8px;
    .breakpoint(@breakpoint-tablet, {
            border-radius: 8px;
            padding: 32px 16px 16px 16px;
        });
    .breakpoint(@breakpoint-laptop-large, {
            width: 100%;
            padding: 0;

        });
    .breakpoint(@breakpoint-mobile-large, {
            padding: 0;
            width: 100%;
            margin: 0 !important;
        });
  }

  .title {
    .h2();
    color: @text-primary-color;
    margin-bottom: 36px;

    .breakpoint(@breakpoint-mobile-large, {
            .mobile-h2();
        });
  }

  .subtitle {
    .h3();
    color: @text-primary-color;
    margin-bottom: 48px;

    .breakpoint(@breakpoint-mobile-large, {
            .mobile-h3();
            margin-bottom: 28px;
        });
  }

  .main-text {
    .main-text();
    color: @text-secondary-color;
  }

  .content-grid {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;

    .breakpoint(@breakpoint-laptop, {
            grid-template-columns: repeat(2, 1fr);
        });
    .breakpoint(@breakpoint-tablet-small, {
            grid-template-columns: repeat(1, 1fr);
        });
  }
}

