@import "../../theme/core.less";

.ooc-page-sub-header {
    margin-bottom: 24px;
    display: flex;
    align-items: baseline;

    &__title {
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
        color: #3B4168;
    }
}

