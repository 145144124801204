@import '../../theme/core.less';

.ooc-breadcrumbs {
    .ant-breadcrumb {
        display: flex;
        &-link {
            font-family: 'IBM Plex Sans';
            font-weight: 500;
            color: @bc-color;

        }
        .laptop{
            .breakpoint(@breakpoint-mobile-large, {
                display: none;
            });
        }

        .mobile{
            display: none;
            .breakpoint(@breakpoint-mobile-large, {
                display: block;
            });
        }

        &-separator {
            color: @bc-color;
        }

        & > span:last-child > .ant-breadcrumb-link {
            color: @primary-color;
        }
        @media screen and (max-width: 820px){
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }

    &__backspace{
        display: flex;
        padding-top: 40px;
        padding-bottom: 24px;
        font-family: @font-content;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;

        .breakpoint(@breakpoint-tablet, {
            background: @bg-secondary;
            border-bottom: 1px solid @border-color;
            border-top: 1px solid @border-color;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        });
        &--icon{
            transform: rotate(180deg);
            margin-right: 12px;
        }
        &--button{
            color: @primary-color;
            display: flex;
            align-items: center;
        }
        &--separator{
            margin: 0 16px;
            color: @text-secondary-color;
        }
        &--links{
            color: @text-secondary-color !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            .catalog-title{
                .breakpoint(@breakpoint-mobile-large, {
                    display: flex;
                });
            }
        }
        &--links:hover{
            color: @primary-color !important;
        }

        .breakpoint(@breakpoint-tablet, {
            padding: 16px 24px 16px 24px;
        });
    }
}

