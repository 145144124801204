@import "../../../../theme/core.less";

.analogs-catalog {
  border-radius: 8px;
  overflow: hidden;

  &__header {
    padding-left: 40px;
    background: #9681F2;
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    margin-bottom: 40px;

    .breakpoint(@breakpoint-laptop, {
      padding: 0 50px;
    });
    .breakpoint(@breakpoint-tablet, {
      padding: 10px 20px 0 20px;
      display: block;
      margin: 16px;
    });
  }

  &__header-title {
    padding: 40px 0;
    width: 848px;
    .breakpoint(@breakpoint-tablet, {
      width: auto;
    });
    .main-text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: @text-white-color!important;
    }
    .title{
      color: @text-white-color!important;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px;
      margin-bottom: 16px!important;

      .breakpoint(@breakpoint-mobile-large, {
        margin-bottom: 10px !important;
      });
    }

    .breakpoint(@breakpoint-laptop, {
      padding: 15px 0px 0px 0px;
    });
  }

  &__header-icon {
    &-mobile{
      display: none;
      .breakpoint(@breakpoint-tablet, {
        display: block;
        text-align: center;
      });
    }
    &-inner {
      height: 100%;
    };

    .breakpoint(1403px, {
      width: 500px;
    });
    .breakpoint(1160px, {
      width: 480px;
    });
    .breakpoint(962px, {
      width: 330px;
    });

    .breakpoint(@breakpoint-tablet, {
      display: none;
    });
  }
  &__body-mobile {
    display: none;
    background: white;
    @media screen and (max-width: 850px){
      padding: 40px 20px;
      display: block;
    }
    .breakpoint(@breakpoint-tablet, {
      margin: 0 16px;
    });
    button {
      color: #0071CE;
      border: 1px solid #0071CE;
      min-width: 100%;
      margin-top: 30px;
    }

    &__block {
      border: 1px solid #E4E7F2;
      border-radius: 8px;
      margin-bottom: 14px;

      .header {
        padding: 10px 20px 10px 20px !important;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #E4E7F2;
        h2{
          color: #12152A;
          font-size: 16px;
          font-weight: 500;
          font-family: @font-content;
          line-height: 20px;
          margin-left: 10px;
          overflow: hidden;
        }

      }
      .content {
        padding: 10px 20px 10px 20px !important;
        p{
          color: #6F7C98;
          font-size: 12px;
          font-weight: 400;
          font-family: @font-content;
          line-height: 16px;
          margin: 0px 0px 8px 0px;
        }
        .ant-tag {
          margin-left: 0px !important;
        }
      }
    }
  }

  &__body {
    padding: 40px;
    background: @bg-secondary;
    overflow: auto;

    .breakpoint(@breakpoint-laptop, {
      padding: 40px 50px;
    });
    .breakpoint(@breakpoint-tablet, {
      padding: 40px 30px;
    });
    @media screen and (max-width: 850px){
      padding: 40px 20px;
      display: none;
    }
    &__table {
      min-width: 650px;
    }
  }

  &__table-block {
    display: flex;
    align-items: center;
    width: 700px;
    .breakpoint(1720px, {
      width: auto;
    });

    &__title {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: @el-black-color;
      margin-bottom: 0;
      padding-left: 24px;
    }
  }

  &__table-cell__icon {
    width: 56px;
    height: 56px;
    font-size: 18px;
    object-fit: contain;
    border-radius: 16px;
  }

  .ant-table {
    .ant-table-thead .ant-table-cell {
      background: @bg-secondary;
      color: @label-color;
      font-size: 16px;
      font-weight: 400;

      &::before {
        content: none !important;
      }
    }

    .ant-table-tbody .ant-table-cell {
      padding-top: 14px;
      padding-bottom: 14px;
      color: @text-primary-color;
      font-size: 16px;
    }

    .ant-table-row:hover .ant-table-cell {
      background: none;
      cursor: pointer;
    }
  }
  .ant-table__even-row {
    background: #FFFFFF; /* Цвет для четных строк */
  }

  .ant-table__odd-row {
    background: #FAFAFF; /* Цвет для нечетных строк */
  }
}

