@import "../../theme/core.less";

.nav-side-menu {
    height: 100%;
    max-width: 300px;
    min-width: 60px;
    font-family: @font-content;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    

    .ant-menu-root .ant-menu-submenu > .ant-menu > .ant-menu-item {
        &.page-selected {
            position: relative; 
            background: linear-gradient(90deg, #0071CE 0%, rgba(0, 113, 206, 0.46) 100%);
            
            &:after {
                clip-path: polygon(100% 0, 6px 50%, 100% 100%); 
                background-color: white;
                position: absolute;
                content: "";
                background: white;
                right: 0;
                top: 0;
                width: 12px;
                height: 100%;
                z-index: 3;
                opacity: 1;
                transform: none;
            }
        }
    }

    .menu-footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: @el-black-color;

        .contact-list {
            padding: 24px 16px;
            display: flex;
            flex-direction: column;

            .list-item {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                &:not(:last-child) {
                    margin-bottom: 16px;
                }

                span {
                    .helptext();
                    color: @text-help-color;
                }
                
                svg {
                    min-width: 16px;
                    height: 16px;
                    margin-right: 8px;

                    path {
                        fill: @text-help-color;
                    }
                }
            }
        }

        .ant-divider {
            margin: 0;
            padding: 0;
            background-color: @el-gray-color;
        }

        .footer-toggle  {
            margin: 16px;
            align-self: flex-end;
            cursor: pointer;
            transition: 0.22s all;

            &.opened {
                transition: 0.33s;
                transform: rotate(180deg)
            }
        }


    }

    & .ant-menu {
        height: 100%;
        user-select: none;
        background: @bc-color;

        &.opened { 
            & > .ant-menu-item {
                .item-link {
                    span {
                        display: none;
                    }
                }
            }
        }
    }

    .ant-menu-root {
        overflow-y: auto;
        overflow-x: hidden;

        & > .ant-menu-item {
            padding-left: 16px !important;  // negate menu.item inline style
            padding-right: 0 !important;
            
            .item-link {
                display: flex;
                align-items: center;
                
                svg {
                    margin-right: 12px;
                    width: 20px;
                    height: 20px;

                    path {
                        fill: white;
                    }
                }
            }


            &.page-selected {
                background: linear-gradient(90deg, #0071CE 0%, rgba(0, 113, 206, 0.46) 100%);
                position: relative; 
        
                &:after {
                    clip-path: polygon(100% 0, 6px 50%, 100% 100%); 
                    background-color: white;
                    position: absolute;
                    content: "";
                    background: white;
                    right: 0;
                    top: 0;
                    width: 12px;
                    height: 100%;
                    content: '';
                    z-index: 3;
                    opacity: 1;
                    transform: none;
                }
            }
        }

        & > .ant-menu-submenu {
            & > .ant-menu-submenu-title {
                padding-left: 16px !important;  // negate menu.item inline style
                padding-right: 0 !important;
            }
        }

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    .ant-menu-root {
        .ant-menu-submenu {
            & > .ant-menu > .ant-menu-item {
                margin: 8px 0;
            }
        }
    }

    .ant-menu-dark.ant-menu-root,
    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
        background: #12152A;
    }

    .ant-menu-item,
    .ant-menu-item a,
    .ant-menu-item span a {
        color: white;
    }

    & .ant-menu-item {
        &:first-child {
            margin: 24px 0;
        }

        & > a {
            flex: auto !important;
            white-space: normal;
            line-height: 20px;
        }
    }

    & .ant-menu-item-selected:not(&__submenu-items--page-selected) {
        background-color: unset !important;
    }

    & .ant-menu-item-icon {
        width: 20px;
        height: fit-content;
        fill: @text-secondary;
    }
}

