@import "../../theme/core.less";

.admin-wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;

  &_content {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 32px 40px 40px 40px;
    width: 1500px;
    max-width: calc(100vw - 40px);
    margin: 0 auto;
  }

  &_tabs.ant-tabs {
    font-family: @font-content;
    line-height: 20px;
    .ant-tabs-tab {
      color: #6F7C98;
      font-size: 16px;
    }
    .ant-tabs-nav {
      padding: 0 28px;
      margin: 0 !important;
      &::before {
        border-bottom: 1px solid #e4e7f2;
      }
    }
    .link {
      margin: 8px;
      font-size: 16px !important;
      font-weight: 400;
      text-decoration: none;
      color: #6f7c98;
      &.active {
        color: #0071ce;
      }
    }
  }

  & .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @primary-color !important;
  }
}

