@import "../../../../../../theme/core.less";

.admin-reviews-pane {

  .reviews-filters-wrapper {
    margin-bottom: 24px;

    .actions {
      float: right;
      margin-left: 16px;
      height: 34px;
      display: inline-flex;
      align-items: center;
      gap: 8px;
    }

    .reviews-filters {
      .filter-search-name {
        width: 330px;
      }

      .row {
        display: flex;
        align-items: center;
        gap: 24px;

        .filter-item {
          margin: 0;
          width: 272px;

          &.filter-select {
            .ant-select-selector {
              border-radius: 10px !important;
              border: none !important;
              box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
            }
          }
        }
      }

      .filter-resetButton {
        display: inline-flex;
        color: #909EBB !important;
      }
    }
  }
    // .admin-item-title {
    //     font-weight: bold;
    // }

    // .admin-list .admin-item-vendor {
    //     font-style: italic;
    // }

    // .admin-item-categories {

    // }
    
    .table-sort-header {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

.reviewModalForm {
  .row {
    display: flex;
    gap: 24px;

    > div {
      flex-grow: 1;
    }
  }
  
  .ant-input[disabled] {
    background-color: @el-bg-color;
    cursor: default;
    border-width: 0 !important;
  }

  .ant-input-affix-wrapper {
    padding: @inp-v2-padding;
    padding-block: 0;

    border: @inp-v2-border;
    border-radius: @inp-v2-border-radius;

    font-family: @inp-font-family;
    font-style: @inp-font-style;
    font-size: @inp-v2-font-size;
    line-height: @inp-v2-line-height;

    &.ant-input-affix-wrapper-focused {
      border-color: #40a9ff;
    }

    .ooc-input-v2 {
      border: none !important;
    }

    .ant-input-suffix {
      .small();
      color: @text-secondary-color;
      position: absolute;
      right: 0;
      top: -2em;
      
      .ant-input-show-count-suffix {
        color: @text-secondary-color;
      }
    }
  }

  .ant-input-textarea-show-count {
    border: @inp-v2-border;
    border-radius: @inp-v2-border-radius;

    &:after {
      .small();
      color: @text-secondary-color;

      position: absolute;
      right: 0;
      top: -2em;
    }

    .ant-input {
      border: none;
      font-family: @inp-font-family;
      font-style: @inp-font-style;
      font-size: @inp-v2-font-size;
      line-height: @inp-v2-line-height;
    }
  }

  .authorAvatar {
    padding: 12px 24px;
    border-radius: 8px;
    background-color: @el-bg-color;
    display: flex;
    align-items: center;
    gap: 24px;

    .avatar img {
      width: 60px;
      border-radius: 100px;
    }

    .info {
      flex-grow: 1;
      
      .label {
        .caption();
        color: @text-secondary-color;
        margin-bottom: 4px;
      }

      .filename {
        .main-text();
        color: @text-primary-color;
      }
    }
  }
  
  .ratingWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    background-color: @el-bg-color;
    padding: 24px 0px;
    align-items: center;
    transition: background 100ms;

    .title {
      .h4();
      color: @text-primary-color;
    }

    .rating {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      color: @border-color;

      .star {
        width: 32px;
        height: 32px;
        cursor: pointer;
        transition: color 100ms;
        
        &.star-empty {
          color: transparent;
          use {
            stroke: @border-color;
            stroke-width: 5;
          }
        }

        &.star-filled {
          color: @el-gray-color;
        }
      }
    }

    &.interactive {
      &:hover {
        background-color: @el-hover-2-color;
      }

      .star.hovered {
        color: @primary-color;
      }
    }
  }

  .ant-form-item-has-error .ratingWrapper,
  .ant-form-item-has-error .ant-input-textarea {
    border-color: #ff4d4f;
  }

  .ant-form-item-has-error .ratingWrapper {
    border-style: solid;
    border-width: 1px;
  }

}

