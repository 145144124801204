@import "../../../../../../theme/core.less";

.promo-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
}

.filter-resetButton {
  display: inline-flex;
  color: #909EBB !important;
}
.ant-space-item:has(.filter-resetButton) {
  display: flex;
  align-items: center;
}
&-with-buttons {
  flex-direction: column;
  width: 100%;
  gap: 8px;
  & > * {
    width: 100%;
  }
}

.admin-vendors-pane {
  .admin-list {
    .admin-item-description {
      max-width: 800px;
      font-style: italic;
    }
  }
}

.admin-form .vendors-list,
.admin-form .applications-list,
.admin-form .feedbacks-list {
  .ant-list-item-meta-avatar .ant-avatar > img {
    object-fit: contain;
  }
}
.dragging {
  background-color: rgba(211, 211, 211, 0.255);
}
.vendors-list {
  .drag-btn {
    cursor: s-resize;
  }
}

.removeWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  color: #3b4168;
}

.removeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.removeTitle {
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.application {
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.applicationLabel {
  color: #e4e7f2;
}

.imgContainer {
  max-height: 100px;
  max-width: 190px;
  border-radius: 8px;
  overflow: hidden;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

