@import "../../../../../../theme/core.less";


.admin-table-modal{
    &__modal-block{
      width: 100%;
      .main-content{
        width: 40% !important;
      }
  }
}

.warning-block{
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: center;
  color: #3B4168;
  font-family: @font-headers;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
.similar-error{
  color: red;
  position: absolute;
  top: 110px;
  left: 85px;
    @media screen and (max-width: 575px){
      top: 190px;
      left: 0;
    }
}
