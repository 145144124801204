@import "../../../../theme/core.less";

.user-reviews {
  margin-top: 60px;

  .reviews-title {
    .h2();
    color: @text-primary-color;
    flex: 1 0 0;
    margin-bottom: 24px;

    .breakpoint(@breakpoint-tablet-small, {
        font-size: 24px;
        font-weight: 600;
      });
  }
}

.swiper-custom-systems {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  position: relative;
  .breakpoint(@breakpoint-tablet, {
    justify-content: center;
  });

  .swiper-scrollbar.swiper-custom-scroll {
    position: static;
    cursor: pointer;
    background-color: #e4e7f252;
    .swiper-scrollbar-drag {
      background-color: #0071ce;
    }
  }

  .swiper-custom-navigation {
    user-select: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    margin-left: auto;
    .breakpoint(@breakpoint-tablet, {
      width: 100%;
    });
  }

  .swiper-custom-prev,
  .swiper-custom-next {
    position: static;
    display: inline-flex;
    margin-top: 0;
    height: initial;
    color: #0071ce;
    .breakpoint(@breakpoint-tablet, {
      flex-grow: 1;
    });

    &.swiper-button-disabled {
      color: #e4e7f2;
      opacity: 1;
    }

    &::after {
      font-size: 16px;
      .breakpoint(@breakpoint-tablet, {
        font-size: 48px;
        text-align: center;
      });
    }
  }

  .swiper-custom-pagination {
    color: #e4e7f2;
    position: static;
    width: initial;
    display: block !important;
    .breakpoint(@breakpoint-tablet, {
      font-size: 32px;
      line-height: 1;
    });

    .swiper-custom-current {
      color: #0071ce;
    }
  }
}

.swiper-scrollbar {
  max-width: 70%;
  .breakpoint(@breakpoint-tablet, {
    display: none;
  });
}

.swiper-slide {
  width: auto !important;
  .breakpoint(450px, {
    width: 100% !important;
  });
}

