@import "../../../../theme/core.less";

.vendor-card {
  border: 1px solid @border-color;
  border-radius: 20px;
  background-color: @bg-secondary;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: 24px;
  min-height: 190px;
  height: 100%;
  .breakpoint(1290px, {
    height: 100%;
  });
  .breakpoint(@breakpoint-mobile-large, {
    padding: 12px;
  });


  .card-preview {
    position: relative;
    display: flex;
    align-items: center;
    .breakpoint(1290px, {
      align-items: flex-start;
    });

    .preview-image {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      z-index: 0;
      width: clamp(80px, 7.5vw, 140px);
      height: clamp(80px, 7.5vw, 140px);
      object-fit: contain;
    }

    .preview-logo {
      width: 88px;
      height: 88px;
      border-radius: 24px;
      font-size: 32px;
      font-weight: 600;
    }
  }

  .card-content {
    position: relative;
    z-index: 2;
    width: 100%;
    padding-left: clamp(16px, 1.5vw, 24px);
    overflow: hidden;

    &__wrap {
      overflow: hidden;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &::-webkit-scrollbar {
      opacity: 0;
    }

    &__header {
      display: flex;
      align-items: center;

      .tag.ant-tag {
        padding: 2px 6px;
        border-radius: 8px;
        font-weight: 500 !important;
        margin-left: 16px !important;
        font-size: 14px;
      }
    }

    .content-name {
      color: @text-primary-color;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      .breakpoint(@breakpoint-mobile-large, {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
      });
    }

    .content-divider {
      margin-top: 0;
      margin-bottom: 16px;
    }

    .content-title {
      .h5();
      margin-bottom: 16px;
      color: @text-primary-color;
    }

    .content-description {
      .caption();
      color: @el-gray-color;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding-right: 12px;

      .ql-editor {
        margin: 0;
        padding: 0;

        & p:hover {
          cursor: pointer;
        }
      }

    }
  }

  .card-category {
    padding-top: 21px;
    .breakpoint(@breakpoint-mobile-large, {
      padding-top: 11px;
    });

    &__list {
      display: flex;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      .breakpoint(1290px, {
        flex-wrap: wrap;
      });

      li {
        padding: 4px 16px 6px 16px;
        border-radius: 100px;
        background: rgba(255, 113, 57, 0.08);
        color: #FF7139;
        margin-right: 8px;
        .breakpoint(1290px, {
          margin-bottom: 8px;
        });
        .breakpoint(@breakpoint-mobile-large, {
          padding: 3px 12px 5px 12px;
        });
      }
    }
  }

  .card-actions {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .actions-divider {
      margin-top: 0;
      margin-bottom: 8px;
    }

    .actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      a, button {
        display: flex;
        align-items: center;

        svg {
          margin-left: 12px;
        }
      }
    }
  }
}

