@import "../../theme/core.less";

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  .main-text();
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

html, body {
  height: auto;
  background: #F5F6F8;
}

#root {
  min-height: calc(100vh - 192px);
  // font-family: @font-family;
}

