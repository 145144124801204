@import '../../theme/core.less';


.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper{
 background-color: #fff;
 border-color: red;
 }

.ant-input.ooc-input-v3 {
    width: 100%;
    height: 44px;
    padding: 12px 16px 12px 16px;
    border: @inp-v2-border;
    border-radius: 8px;

    font-family: @inp-font-family;
    font-style: @inp-font-style;
    font-size: @inp-v2-font-size;
    line-height: @inp-v2-line-height;
    color: @text-primary-color;
}

.form-input-mask{
 .ant-input-affix-wrapper {
    width: 100%;
    height: 44px;
    padding: 12px 16px 12px 16px;
    border: @inp-v2-border;
    border-radius: 8px !important;

    font-family: @inp-font-family;
    font-style: @inp-font-style;
    font-size: @inp-v2-font-size;
    line-height: @inp-v2-line-height;
    color: @text-help-color;
}
}

.ant-input.ooc-input-v3::placeholder{
    color: @text-help-color;
}


.ooc-input-v3 {
    &__form-input {
        
        .ant-form-item-label label {
            color: #6F7C98;
            
        }

        .ant-input {
            border: 1px solid #E4E7F2;
            border-radius: 4px;
            
        }
    }
    &__form-input-mask {
        width: 100%;
        height: 44px;
        padding: 12px 16px 12px 16px;
        border: @inp-v2-border;
        border-radius: 8px;
    
        font-family: @inp-font-family;
        font-style: @inp-font-style;
        font-size: @inp-v2-font-size;
        line-height: @inp-v2-line-height;
        color: @text-help-color;
    }
}

.ooc-input-v3__textarea {
    & > textarea {
        padding: 8px 16px;
    }

    & > textarea.ant-input-borderless {
        padding: 0;
        height: fit-content;
        overflow: hidden;
        resize: none;
    }
}

