@import '../../theme/core.less';

.ooc-logo {
    padding-left: 16px;
    box-sizing: content-box;

    .anticon {
        width: 100%;
        height: 100%;

        svg {
            width: inherit;
            height: inherit;
        }
    }

    @media screen and (max-width: 1366px) {
        height: 44px;
    }

    @media screen and (min-width: 1366px) and (max-width: 1920px) {
        height: 49px;
    }

    @media screen and (min-width: 1920px) {
        height: 56px;
    }
}

